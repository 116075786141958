import React from "react";
import "./PetServicesSection.css";

const PetServicesSection = () => {
  const services = [
    {
      image: "https://cdn.thelittlepetshoppe.com/dog-training-service.png",
      title: "Dog Training",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus vitae augue in odio lobortis euismod.",
      hoverTitle: "TRAINING",
      hoverSubtitle: "by Little pet",
      hoverIcon: "https://cdn.thelittlepetshoppe.com/dogtrainingicon.png",
    },
    {
      image: "https://cdn.thelittlepetshoppe.com/dog-grooming-service.png",
      title: "Pet Grooming",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus vitae augue in odio lobortis euismod.",
      hoverTitle: "GROOMING",
      hoverSubtitle: "by Lorem",
      hoverIcon: "https://cdn.thelittlepetshoppe.com/doggroomingicon.png",
    },
    {
      image: "https://cdn.thelittlepetshoppe.com/dog-walking-service.png",
      title: "Walking",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus vitae augue in odio lobortis euismod.",
      hoverTitle: "DOG WALKING",
      hoverSubtitle: "by Little pet shoppe",
      hoverIcon: "https://cdn.thelittlepetshoppe.com/dogwalkingicon.png",
    },
  ];

  return (
    <div className="pet-services">
      <h2 className="pet-services-title">Pet Services We Recommend</h2>
      <div className="pet-services-cards">
        {services.map((service, index) => (
          <div key={index} className="pet-service-card">
            {/* Image with Hover Effect */}
            <div className="pet-services-image-wrapper">
              <img
                src={service.image}
                alt={service.title}
                className="pet-service-image"
              />
              <div className="pet-services-card-hover">
                <h3 className="pet-services-hover-title">{service.hoverTitle}
                  <img
                  src={service.hoverIcon}
                  alt={`${service.title} icon`}
                  className="pet-services-hover-icon"
                  />
                </h3>
                <p className="pet-services-hover-subtitle">
                  {service.hoverSubtitle}
                </p>
              </div>
            </div>

            {/* Text Content Below */}
            <h3 className="pet-service-title">{service.title}</h3>
            <p className="pet-service-description">{service.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PetServicesSection;