import React, { useState, useEffect } from "react";
import "./CookieBar.css"; // Import the styles

const CookieBar = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 10000); // Show after 10 seconds
    return () => clearTimeout(timer); // Cleanup timer on component unmount
  }, []);

  const handleClose = () => {
    setIsVisible(false);
  };

  return (
    isVisible && (
      <div className="cookie-bar">
        <div className="cookie-bar-content">
          <h3>Our Website Uses Cookies</h3>
          <p>
            We use cookies and similar technologies to help personalize content,
            tailor and measure ads, and provide a better experience. By clicking
            accept, you agree to this, as outlined in our Cookies Policy.
          </p>
        </div>
        <div className="cookie-bar-buttons">
          <button className="accept-all" onClick={handleClose}>
            ACCEPT ALL COOKIES
          </button>
          <button className="necessary-cookies" onClick={handleClose}>
            ONLY NECESSARY COOKIES
          </button>
          <button className="manage-cookies" onClick={handleClose}>
            MANAGE COOKIES
          </button>
        </div>
      </div>
    )
  );
};

export default CookieBar;

//import React, { useState, useEffect } from "react";
//
//const CookieBar = () => {
//  const [isVisible, setIsVisible] = useState(false);
//  const [cookiePreferences, setCookiePreferences] = useState({
//    necessary: true, // Always required
//    functional: false,
//    analytics: false,
//    advertising: false
//  });
//
//  useEffect(() => {
//    // Check if user has existing preferences
//    const savedPreferences = localStorage.getItem('cookiePreferences');
//    if (savedPreferences) {
//      setCookiePreferences(JSON.parse(savedPreferences));
//    } else {
//      // Show banner if no preferences are saved
//      const timer = setTimeout(() => setIsVisible(true), 10000);
//      return () => clearTimeout(timer);
//    }
//  }, []);
//
//  const handleCookiePreferences = (type) => {
//    switch(type) {
//      case 'acceptAll':
//        const allAccepted = {
//          necessary: true,
//          functional: true,
//          analytics: true,
//          advertising: true
//        };
//        setCookiePreferences(allAccepted);
//        saveCookiePreferences(allAccepted);
//        break;
//
//      case 'necessary':
//        const necessaryOnly = {
//          necessary: true,
//          functional: false,
//          analytics: false,
//          advertising: false
//        };
//        setCookiePreferences(necessaryOnly);
//        saveCookiePreferences(necessaryOnly);
//        break;
//
//      default:
//        break;
//    }
//    setIsVisible(false);
//  };
//
//  const saveCookiePreferences = (preferences) => {
//    localStorage.setItem('cookiePreferences', JSON.stringify(preferences));
//    // Here you would implement the actual cookie setting logic
//    applyPreferences(preferences);
//  };
//
//  const applyPreferences = (preferences) => {
//    if (preferences.necessary) {
//      // Set necessary cookies (session, security, etc.)
//      setCookie('session_cookie', 'value', 1); // 1 day expiry
//    }
//
//    if (preferences.functional) {
//      // Set functional cookies (language preference, theme, etc.)
//      setCookie('language_pref', 'value', 30);
//      setCookie('theme_pref', 'value', 30);
//    }
//
//    if (preferences.analytics) {
//      // Set analytics cookies
//      setCookie('analytics_id', 'value', 365);
//    }
//
//    if (preferences.advertising) {
//      // Set advertising cookies
//      setCookie('ad_preferences', 'value', 180);
//    }
//  };
//
//  const setCookie = (name, value, daysToExpire) => {
//    const date = new Date();
//    date.setTime(date.getTime() + (daysToExpire * 24 * 60 * 60 * 1000));
//    document.cookie = `${name}=${value};expires=${date.toUTCString()};path=/;SameSite=Strict`;
//  };
//
//  return (
//    isVisible && (
//      <div className="cookie-bar">
//        <div className="cookie-bar-content">
//          <h3>Cookie Settings</h3>
//          <div className="cookie-categories">
//            <div className="cookie-category">
//              <h4>Necessary Cookies</h4>
//              <p>Essential for website functionality. Cannot be disabled.</p>
//              <input type="checkbox" checked disabled />
//            </div>
//
//            <div className="cookie-category">
//              <h4>Functional Cookies</h4>
//              <p>For enhanced functionality and personalization.</p>
//              <input
//                type="checkbox"
//                checked={cookiePreferences.functional}
//                onChange={(e) => setCookiePreferences({
//                  ...cookiePreferences,
//                  functional: e.target.checked
//                })}
//              />
//            </div>
//
//            <div className="cookie-category">
//              <h4>Analytics Cookies</h4>
//              <p>Help us improve our website by collecting usage information.</p>
//              <input
//                type="checkbox"
//                checked={cookiePreferences.analytics}
//                onChange={(e) => setCookiePreferences({
//                  ...cookiePreferences,
//                  analytics: e.target.checked
//                })}
//              />
//            </div>
//
//            <div className="cookie-category">
//              <h4>Advertising Cookies</h4>
//              <p>Used to show you relevant personalized ads.</p>
//              <input
//                type="checkbox"
//                checked={cookiePreferences.advertising}
//                onChange={(e) => setCookiePreferences({
//                  ...cookiePreferences,
//                  advertising: e.target.checked
//                })}
//              />
//            </div>
//          </div>
//        </div>
//
//        <div className="cookie-bar-buttons">
//          <button onClick={() => handleCookiePreferences('acceptAll')}>
//            Accept All
//          </button>
//          <button onClick={() => handleCookiePreferences('necessary')}>
//            Necessary Only
//          </button>
//          <button onClick={() => saveCookiePreferences(cookiePreferences)}>
//            Save Preferences
//          </button>
//        </div>
//      </div>
//    )
//  );
//};
//
//export default CookieBar;
