import React from "react";
import "./CartHeaderOption.css";
import CartDropdown from "./CartDropdown";
import CartIcon from "./CartIcon"; // Your CartIcon component
import ArrowIcon from "./ArrowIcon"; // Your ArrowIcon component
import FloatingContainer from "./FloatingContainer";

const CartHeaderOption = () => {
  // Define products directly in CartOption
  const products = [
    {
      title: "Product 1",
      price: 4.86,
      quantity: 3,
    },
    {
      image: "",
      title: "Product 2",
      price: 45.86,
      quantity: 1,
    },
    {
      image: "",
      title: "Product 3",
      price: 79.66,
      quantity: 1,
    },
    {
      title: "Product 4",
      price: 10.11,
      quantity: 2,
    },
    {
      title: "Product 5",
      price: 10.11,
      quantity: 2,
    },
    {
      title: "Product 6",
      price: 10.11,
      quantity: 2,
    },
    {
      title: "Product 7",
      price: 10.11,
      quantity: 2,
    },
    {
      title: "Product 8",
      price: 10.11,
      quantity: 2,
    },
    {
      title: "Product 9",
      price: 10.11,
      quantity: 2,
    },
  ];

  // Calculate subtotal
  const subtotal = products.reduce(
    (total, product) => total + product.price * product.quantity,
    0
  );

  return (
    <div className="cart-option-container">
      {/* Hover Trigger */}
      <div className="cart-option">
        <div className="cart-icon-container">
          <CartIcon width="20px" height="20px" className="icon" />
        </div>
        <span>
          Cart <ArrowIcon />
        </span>
      </div>

      {/* Floating Dropdown */}
      <FloatingContainer customTriangleOffset="84.8%" leftPosition="-125px" maxHeight="500px">
        <CartDropdown products={products} subtotal={subtotal} />
      </FloatingContainer>
    </div>
  );
};

export default CartHeaderOption;


const section_id = "section-content-with-grid-ba4996f1-0355-4901-9351-3cbd1047e4c9"; // Original section ID
const breaking_point = 960;

const replaceSectionForMobile = () => {
    const section = document.getElementById(section_id);

    if (window.innerWidth < breaking_point && section) {
        // Find the parent container of the section
        const parent = section.parentNode;

        // Remove the original section
        parent.removeChild(section);

        // Create a new container for the replacement
        const newSection = document.createElement("div");
        newSection.className = "mobile-upsell-container";
        newSection.innerHTML = `
            <div class="mobile-upsell-item">
                <div class="icon">
                    <img src="https://www.fulfillmentcenterca.com/wp-content/uploads/go-x/u/04878b2d-4c76-48b7-88f0-31179ae97999/l0,t0,w1024,h1024/image-375x375.png" alt="Rapid Turnaround Icon">
                </div>
                <h2>RAPID TURNAROUND</h2>
                <p>Same-day processing & shipping</p>
            </div>
            <div class="mobile-upsell-item">
                <div class="icon">
                    <img src="https://www.fulfillmentcenterca.com/wp-content/uploads/go-x/u/d6a50fc5-27b3-45d4-8da1-0156a4ccef9c/l0,t0,w550,h550/image.png" alt="Customer Satisfaction Icon">
                </div>
                <h2>CUSTOMER SATISFACTION</h2>
                <p>Consistently exceed expectations to build lasting loyalty</p>
            </div>
            <div class="mobile-upsell-item">
                <div class="icon">
                    <img src="https://www.fulfillmentcenterca.com/wp-content/uploads/go-x/u/aee32014-ab97-4431-ba32-abae1aa6df8e/l0,t0,w1024,h1024/image-375x375.png" alt="Scalable Solutions Icon">
                </div>
                <h2>SCALABLE SOLUTIONS</h2>
                <p>Flexible for seasonal and business growth</p>
            </div>
            <div class="mobile-upsell-item">
                <div class="icon">
                    <img src="https://www.fulfillmentcenterca.com/wp-content/uploads/go-x/u/f8a1b276-b650-40f1-a94f-d51e7d95365a/l2,t0,w722,h722/image-375x375.png" alt="Advanced Technology Icon">
                </div>
                <h2>ADVANCED TECHNOLOGY</h2>
                <p>Real-time tracking and inventory management</p>
            </div>
            <div class="mobile-upsell-item">
                <div class="icon">
                    <img src="https://www.fulfillmentcenterca.com/wp-content/uploads/go-x/u/4f23c31b-52dc-4789-8a92-df0fb4c47583/l0,t0,w1024,h1024/image-375x375.png" alt="Cost Efficiency Icon">
                </div>
                <h2>COST EFFICIENCY</h2>
                <p>Competitive pricing, premium service</p>
            </div>
        `;

        // Insert the new section into the parent container
        parent.appendChild(newSection);
    }
};

// Run the function on page load and resize
window.addEventListener("resize", replaceSectionForMobile);
replaceSectionForMobile();
