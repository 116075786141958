import React from "react";
import "./BoxieCatProductDisplay.css";

const BoxieCatProductDisplay = () => {
  return (
    <div className="boxiecat-product">
      {/* Images */}
      <img
        src="https://cdn.thelittlepetshoppe.com/boxiecat-litter.png "
        alt="Placeholder Image"
        className="boxiecat-litter-image"
      />
      <img
        src="https://cdn.thelittlepetshoppe.com/Boxiecat-litter-bag.png"
        alt="Placeholder Image"
        className="boxiecat-product-litter"
      />
      <img
        src="https://cdn.thelittlepetshoppe.com/boxiecat-litterscoop-shadow.png"
        alt="Placeholder Image"
        className="boxiecat-product-scoop-shadow"
      />
      <img
        src="https://cdn.thelittlepetshoppe.com/boxiecat-litter-scoop.png"
        alt="Placeholder Image"
        className="boxiecat-product-scoop"
      />
    </div>
  );
};

export default BoxieCatProductDisplay;
