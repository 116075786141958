// src/components/Header.js
import React, { useState, useRef, useEffect } from 'react';
import './Header.css'; // Importing custom styles
import { FaSearch, FaUser, FaShoppingCart, FaBars } from 'react-icons/fa';
import ArrowIcon from './ArrowIcon';
import SupportIcon from './SupportIcon';
import SignInIcon from './SignInIcon';
import CartIcon from './CartIcon';
import MenuBar from './MenuBar';
import LittlePetExpressLogo from './LittlePetExpressLogo';
import DropdownExample from './DropdownExample'
import ExpertSupport from './ExpertSupport'
import SignInOptionBlock from './SignInOptionBlock'
import CartHeaderOption from "./CartHeaderOption";


const Header = ({ isMenuVisible }) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  // Toggle dropdown visibility when the hamburger menu icon is clicked
  const handleMenuToggle = () => {
    setIsDropdownVisible((prev) => !prev);
  };

  // Close the dropdown when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setIsDropdownVisible(false);
      }
    };

    if (isDropdownVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDropdownVisible]);

  // Effect to reset the dropdown visibility when `isMenuVisible` changes
  useEffect(() => {
    // Reset dropdown visibility when the hamburger icon's visibility changes
    if (!isMenuVisible) {
      setIsDropdownVisible(false);
    }
  }, [isMenuVisible]);

  return (
    <header className="header">
      {/* Hamburger Menu Icon with Click-Based Dropdown */}
        {!isMenuVisible && (
          <div className="menu-toggle-button" onClick={handleMenuToggle} ref={dropdownRef}>
            <FaBars className="icon" />

            {/* Dropdown Menu */}
            {isDropdownVisible && (
              <MenuBar className="menu-dropdown" />
            )}
          </div>
        )}
      <div className="logo-section">
        <LittlePetExpressLogo className="logo" />
      </div>

      <div className="search-bar">
        <input type="text" placeholder="Search" className="search-input" />
        <button className="search-button">
          <FaSearch />
        </button>
      </div>

      <div className="support-user-cart-section">

        <ExpertSupport />
        <SignInOptionBlock />
        <CartHeaderOption />

      </div>
    </header>
  );
};

export default Header;
