import * as React from "react";

const BoxieCatBadgeShape = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    viewBox="0 0 91.18 91.6"
    className={className}
  >
    {/* Badge Shape */}
    <path
      d="M67.32 81.76c-3.97-2.87-9.56-.53-10.32 4.3-1.03 6.61-10.14 7.61-12.58 1.38-1.79-4.56-7.75-5.62-11-1.96-4.44 5-12.64.92-11.33-5.64.96-4.8-3.48-8.92-8.2-7.6-6.44 1.81-11.13-6.06-6.48-10.87 3.4-3.52 1.89-9.39-2.79-10.82-6.39-1.96-6.09-11.12.42-12.65 4.76-1.12 6.66-6.87 3.51-10.61-4.32-5.11.89-12.64 7.19-10.41 4.61 1.63 9.32-2.18 8.69-7.03-.87-6.63 7.58-10.15 11.68-4.87 3 3.87 9.02 3.21 11.11-1.22 2.85-6.05 11.87-4.44 12.46 2.22.43 4.87 5.86 7.57 10 4.98 5.67-3.55 12.39 2.68 9.28 8.6-2.27 4.33.83 9.54 5.72 9.6 6.69.08 8.97 8.95 3.16 12.25-4.25 2.42-4.46 8.47-.37 11.17 5.58 3.68 2.71 12.38-3.97 12.01-4.89-.27-8.33 4.72-6.35 9.19 2.7 6.12-4.41 11.88-9.83 7.96Z"
      style={{
        fill: "#053d7a",
      }}
    />
    {/* Text Elements */}
    <text
      x="50%"
      y="30%"
      textAnchor="middle"
      fontSize="10"
      fill="white"
      fontFamily="Arial, sans-serif"
    >
      • 2023 •
    </text>
    <text
      x="50%"
      y="55%"
      textAnchor="middle"
      fontSize="24"
      fill="#36A4FF"
      style={{
        fontFamily: "acumin-variable, sans-serif",
        fontVariationSettings: "'wght' 600, 'wdth' 115, 'slnt' 0",
      }}
    >
      Best
    </text>
    <text
      x="50%"
      y="68%"
      textAnchor="middle"
      fontSize="8"
      fill="white"
      style={{
        fontFamily: "acumin-variable, sans-serif",
        fontVariationSettings: "'wght' 382.71605, 'wdth' 115, 'slnt' 0",
      }}
    >
      Clumping
    </text>
    <text
      x="50%"
      y="78%"
      textAnchor="middle"
      fontSize="8"
      fill="white"
      style={{
        fontFamily: "acumin-variable, sans-serif",
        fontVariationSettings: "'wght' 382.71605, 'wdth' 115, 'slnt' 0",
      }}
    >
      Cat Litter
    </text>
  </svg>
);

export default BoxieCatBadgeShape;
