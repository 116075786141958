import React from "react";
import FAQ from "./FAQ";

const BoxieCatFAQ = () => {
  const boxieCatQuestions = [
    {
      question: "HOW IT WORKS",
      answer:
        "Selected probiotics are harmless, beneficial living microorganisms that love to digest waste and are effective at controlling ammonia odors. Boxie® Pro™ litters contain a proprietary blend of probiotics that help to deodorize cat litter without harmful disinfectants, enzymatics, or olfactory masking agents, resulting in longer-lasting odor control.",
    },
    {
      question: "RESULTS",
      answer: [
        "40-day odor control",
        "Airtight™ clumps are 3x stronger than other plant-based litters",
        "Probiotic solutions work 24/7 to clean litter after every use",
      ],
    },
  ];

  return <FAQ data={boxieCatQuestions} />;
};

export default BoxieCatFAQ;
