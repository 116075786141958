// src/components/MenuBar.js
import React from 'react';
import './MenuBar.css';
import ArrowIcon from './ArrowIcon'; // Import the ArrowIcon component

const MenuBar = ({ className = "menu-bar" }) => {
  return (
    <div className={className}>
      <ul className="menu-list">
        <li className="menu-item">Shop Local</li>
        <li className="menu-item contains-dropdown">
          Dog <ArrowIcon />
          <div className="dropdown">
            {/* Dropdown content can be added here */}
            <p>Dog Food</p>
            <p>Dog Toys</p>
            <p>Dog Accessories</p>
          </div>
        </li>
        <li className="menu-item contains-dropdown">
          Cat <ArrowIcon />
          <div className="dropdown">
            {/* Dropdown content can be added here */}
            <p>Cat Food</p>
            <p>Cat Toys</p>
            <p>Cat Accessories</p>
          </div>
        </li>
        <li className="menu-item contains-dropdown">
          Categories <ArrowIcon />
          <div className="dropdown">
            {/* Dropdown content can be added here */}
            <p>Food</p>
            <p>Toys</p>
            <p>Accessories</p>
          </div>
        </li>
        <li className="menu-item contains-dropdown">
          Brands <ArrowIcon />
          <div className="dropdown">
            {/* Dropdown content can be added here */}
            <p>Brand A</p>
            <p>Brand B</p>
            <p>Brand C</p>
          </div>
        </li>
        <li className="menu-item contains-dropdown">
          Services <ArrowIcon />
          <div className="dropdown">
            {/* Dropdown content can be added here */}
            <p>Grooming</p>
            <p>Training</p>
            <p>Veterinary</p>
          </div>
        </li>
        <li className="menu-item">Wholesale</li>
      </ul>
    </div>
  );
};

export default MenuBar;
