import React from "react";
import "./CartDropdown.css";
import PropTypes from "prop-types";
import { FaImage } from "react-icons/fa";

const CartDropdown = ({ products, subtotal }) => {
  return (
    <div className="cart-dropdown">
      {/* Fixed Top Section */}
      <div className="cart-dropdown-header">
        <div className="cart-subtotal">
          <span>Cart Subtotal: </span>
          <span>${subtotal.toFixed(2)}</span>
        </div>
        <div className="cart-shipping">
          Your order ships <b>FREE</b> 🚚
        </div>
      </div>
      <div className="cart-dropdown-buttons">
          <button className="view-cart-button">View Cart</button>
          <button className="checkout-button">Proceed to Checkout</button>
      </div>

      {/* Scrollable Product List */}
      <div className="cart-items">
        {products.map((product, index) => (
          <div key={index} className="cart-item">
            {product.image ? (
              <img
                src={product.image}
                alt={product.title}
                className="cart-item-image"
              />
            ) : (
              <FaImage className="cart-item-placeholder cart-item-image" />
            )}
            <div className="cart-item-details">
              <p className="cart-item-title">{product.title}</p>
              <p className="cart-item-price">
                ${product.price.toFixed(2)}
                <span className="cart-item-quantity"> (Qty: {product.quantity})</span>
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

CartDropdown.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      title: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
      quantity: PropTypes.number.isRequired,
    })
  ).isRequired,
  subtotal: PropTypes.number.isRequired,
};

export default CartDropdown;
