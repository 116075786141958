import React from 'react';
import ProductCollection from './ProductCollection';

const catProducts = [
  {
      id: 1,
      name: 'Green Juju',
      image: '',
      brand: 'Brand Name',
      price: '19.99',
    },
    {
      id: 2,
      name: 'The Healer',
      image: '',
      brand: 'Brand Name',
      price: '19.99',
    },
    {
      id: 3,
      name: 'Lotus',
      image: '',
      brand: 'Brand Name',
      price: '19.99',
    },
    {
      id: 4,
      name: 'Lotus',
      image: '',
      brand: 'Brand Name',
      price: '19.99',
    },
    {
      id: 5,
      name: 'Lotus',
      image: '',
      brand: 'Brand Name',
      price: '19.99',
    },
    {
      id: 6,
      name: 'Lotus',
      image: '',
      brand: 'Brand Name',
      price: '19.99',
    },
    {
      id: 7,
      name: 'Lotus',
      image: '',
      brand: 'Brand Name',
      price: '19.99',
    },
    {
      id: 8,
      name: 'Lotus',
      image: '',
      brand: 'Brand Name',
      price: '19.99',
    },
    {
      id: 9,
      name: 'Lotus',
      image: '',
      brand: 'Brand Name',
      price: '19.99',
    },
    {
      id: 10,
      name: 'Catnip Toy',
      image: '',
      brand: 'Purrfect Toys',
      price: '12.99'
    },
    { id: 11,
      name: 'Scratching Post',
      image: '',
      brand: 'Purrfect Habitat',
      price: '29.99'
    },
    { id: 12,
      name: 'Wet Food',
      image: '',
      brand: 'Weruva',
      price: '1.99'
    }
];

const CatProductCollection = () => (
  <ProductCollection title="Cat Products" products={catProducts} />
);

export default CatProductCollection;
