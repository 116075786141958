import React from "react";
import "./BoxieCatProductFeatures.css";
import BoxieCatFAQ from "./BoxieCatFAQ";
import BoxieCatCatIcon from "./BoxieCatCatIcon";
import BoxieCatCleanIcon from "./BoxieCatCleanIcon";
import BoxieCatOdorIcon from "./BoxieCatOdorIcon";
import BoxieCatProbioticIcon from "./BoxieCatProbioticIcon";

const BoxieCatProductFeatures = () => {
  return (
    <div className="boxiecat-container">
      {/* Title Section */}
      <div className="boxiecat-header">
        <h1>
          Think outside <span className="boxiecat-highlight">(and inside)</span>{" "}
          the box.
        </h1>

      </div>

      {/* No Section */}
      <div className="boxiecat-no-section">
        <div className="boxiecat-no-item">
          <h2 className="boxiecat-no">NO</h2>
          <p className="boxiecat-no-description">ARTIFICIAL <br/> CLUMPING AIDS</p>
        </div>
        <div className="boxiecat-no-item">
          <h2 className="boxiecat-no">NO</h2>
          <p className="boxiecat-no-description">CHEAP <br/> FILLERS</p>
        </div>
        <div className="boxiecat-no-item">
          <h2 className="boxiecat-no">NO</h2>
          <p className="boxiecat-no-description">LITTER <br/> PRESERVATIVES</p>
        </div>
      </div>

      {/* How Probiotics Work Section */}
      <div className="boxiecat-how-probiotics">
        <h3>
          How <span className="boxiecat-highlight">Probiotics</span> Work
        </h3>
        <div className="boxiecat-steps">
          <div className="boxiecat-step">
            <div className="boxiecat-step-text">
              <h4>01</h4>
            </div>
            <BoxieCatCatIcon className="boxiecat-step-image cat-icon" />
            <div className="boxiecat-step-text">
              <p>Cat uses litter box.</p>
            </div>
          </div>
          <div className="boxiecat-step">
            <div className="boxiecat-step-text">
              <h4>02</h4>
            </div>
            <BoxieCatCleanIcon className="boxiecat-step-image clean-icon" />
            <div className="boxiecat-step-text">
              <p>Dormant probiotics are activated by the presence of waste in the litter box.</p>
            </div>
          </div>
          <div className="boxiecat-step">
            <div className="boxiecat-step-text">
              <h4>03</h4>
            </div>
            <BoxieCatOdorIcon className="boxiecat-step-image odor-icon"  />
            <div className="boxiecat-step-text">
              <p>Boxie probiotics consume odor causing bacteria present in the waste, eliminating odor and cleaning the litter.</p>
            </div>
          </div>
          <div className="boxiecat-step">
            <div className="boxiecat-step-text">
              <h4>04</h4>
            </div>
            <BoxieCatProbioticIcon className="boxiecat-step-image probiotic-icon" />
            <div className="boxiecat-step-text">
              <p>
              Probiotics return back to their dormant state and prepare for their next feast!
            </p>
            </div>
          </div>
        </div>
      </div>

      {/* How It Works Section */}
      <div className="boxiecat-faqs">
        {/* Other content can go here */}
        <div className="boxiecat-faq-section">
          <BoxieCatFAQ />
        </div>
      </div>
    </div>
  );
};

export default BoxieCatProductFeatures;
