import React, { useRef } from 'react';
import './PetCollections.css'; // Import CSS for styling
import { FaChevronLeft, FaChevronRight, FaImage } from 'react-icons/fa'; // Import icons for scrolling and placeholder

const PetCollections = ({ title, collectionItems }) => {
  const containerRef = useRef(null);

  // Function to scroll to the next set of items
  const handleNextClick = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: 300, behavior: 'smooth' });
    }
  };

  // Function to scroll to the previous set of items
  const handlePrevClick = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: -300, behavior: 'smooth' });
    }
  };

  // Placeholder icon for items without images
  const placeholderImage = <FaImage className="placeholder-icon" />;

  return (
    <div className="pet-collections-wrapper">
      <h2 className="collection-title">{title}</h2>
      <div className="scroll-container">
        <button className="scroll-button left" onClick={handlePrevClick}>
          <FaChevronLeft />
        </button>
        <div className="pet-collections" ref={containerRef}>
          {collectionItems.map((item) => (
            <div className="pet-collection-item" key={item.id}>
              <div className="pet-collection-image-container">
                {item.image ? (
                  <img
                    src={item.image}
                    alt={item.name}
                    className="pet-collection-image"
                  />
                ) : (
                  placeholderImage // Fallback placeholder icon if no image is provided
                )}
              </div>
              <p className="pet-collection-name">{item.name}</p>
            </div>
          ))}
        </div>
        <button className="scroll-button right" onClick={handleNextClick}>
          <FaChevronRight />
        </button>
      </div>
    </div>
  );
};

export default PetCollections;
