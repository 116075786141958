// src/components/CategoryCollection.js
import React, { useRef, useState, useEffect } from 'react';
import './CategoryCollection.css';
import { FaImage, FaChevronLeft, FaChevronRight } from 'react-icons/fa'; // Importing icons from react-icons

const CategoryCollection = () => {
  const collectionItems = [
    { label: 'Dog' },
    { label: 'Cat' },
    { label: 'Wet Food' },
    { label: 'Dry Food' },
    { label: 'Treats' },
    { label: 'Toys' },
    { label: 'Apparel' },
    { label: 'Leashes' },
    { label: 'Litter' },
    { label: 'Grooming' },
    { label: 'Enrichment' },
    { label: 'Travel' },
  ];

  const containerRef = useRef(null);
  const [showPrevButton, setShowPrevButton] = useState(false);
  const [showNextButton, setShowNextButton] = useState(true);

  const scrollDistance = 300; // Amount to scroll horizontally

  // Function to check the visibility of the "Previous" and "Next" buttons
  const checkButtonVisibility = () => {
    if (containerRef.current) {
      const maxScrollLeft = containerRef.current.scrollWidth - containerRef.current.clientWidth;
      const currentScrollLeft = containerRef.current.scrollLeft;

      // Update the visibility of the buttons
      setShowPrevButton(currentScrollLeft > 0);
      setShowNextButton(currentScrollLeft < maxScrollLeft);
    }
  };

  // Function to scroll to the next set of items
  const handleNextClick = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: scrollDistance, behavior: 'smooth' });

      // Check visibility after scrolling
      setTimeout(checkButtonVisibility, 300);
    }
  };

  // Function to scroll to the previous set of items
  const handlePrevClick = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: -scrollDistance, behavior: 'smooth' });

      // Check visibility after scrolling
      setTimeout(checkButtonVisibility, 300);
    }
  };

  // Add an event listener to update button visibility when the user manually scrolls
  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', checkButtonVisibility);
    }

    // Cleanup event listener on component unmount
    return () => {
      if (container) {
        container.removeEventListener('scroll', checkButtonVisibility);
      }
    };
  }, []);

  return (
    <div className="collections-container">
      <h2 className="collections-title">EXPLORE OUR COLLECTIONS</h2>
      <div className="scroll-wrapper">
        {showPrevButton && (
          <button className="scroll-button prev-button" onClick={handlePrevClick}>
            <FaChevronLeft />
          </button>
        )}
        <div className="collections-items" ref={containerRef}>
          {collectionItems.map((item, index) => (
            <div className="collection-item" key={index}>
              <div className="collection-icon">
                <FaImage className="placeholder-icon" /> {/* Placeholder icon */}
              </div>
              <p className="collection-label">{item.label}</p>
            </div>
          ))}
        </div>
        {showNextButton && (
          <button className="scroll-button next-button" onClick={handleNextClick}>
            <FaChevronRight />
          </button>
        )}
      </div>
    </div>
  );
};

export default CategoryCollection;
