import React, { useState } from "react";
import "./FAQ.css";

const FAQ = ({ data }) => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleAnswer = (index) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className="faq">
      {data.map((item, index) => (
        <div className="faq-item" key={index}>
          <div
            className="faq-question"
            onClick={() => toggleAnswer(index)}
          >
            <h4>{item.question}</h4>
            <span className="faq-icon">{expandedIndex === index ? "-" : "+"}</span>
          </div>
          {expandedIndex === index && (
            <div className="faq-answer">
              {Array.isArray(item.answer) ? (
                <ul className="faq-answer-list">
                  {item.answer.map((point, i) => (
                    <li key={i}>{point}</li>
                  ))}
                </ul>
              ) : (
                <p>{item.answer}</p>
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default FAQ;
