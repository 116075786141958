import React, { useEffect, useRef, useState } from 'react';
import './TopBar.css';

const TopBar = ({ message, speed }) => {
  const textRef = useRef(null);
  const containerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);

  useEffect(() => {
    if (containerRef.current) {
      // Set the width of the container
      setContainerWidth(containerRef.current.offsetWidth);
    }
  }, []);
  const repeatedMessage = `${message} \u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0
  ${message} \u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0 ${message}`;
  return (
    <div className="top-bar">
      {/* Animated Text */}
      <div className="top-bar-text-container" ref={containerRef}>
        <div
          className="top-bar-text"
          ref={textRef}
          style={{
            '--animation-distance': `${containerWidth}px`, // Custom property for animation
            animation: `scrollText ${speed}s linear infinite`,
          }}
        >
          {repeatedMessage}
        </div>
      </div>

      {/* Menu Items */}
      <nav className="top-bar-menu">
        <a href="#about" className="menu-item">About Us</a>
        <a href="#blog" className="menu-item">Blog</a>
        <a href="#donate" className="menu-item">Donate</a>
        <a href="#contact" className="menu-item">Contact Us</a>
      </nav>
    </div>
  );
};

export default TopBar;
