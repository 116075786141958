import React, { useEffect, useRef, useState } from "react";
import "./SignInPopup.css";
import LittlePetExpressLogo from "./LittlePetExpressLogo";

const SignInPopup = ({ onClose }) => {
  const popupRef = useRef(null);

  const [isPasswordStep, setIsPasswordStep] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is outside the pop-up
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose(); // Trigger the onClose function
      }
    };

    // Add event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup event listener on unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const handleContinue = (e) => {
    e.preventDefault();
    setIsPasswordStep(true); // Hide "Forgot email?" and show "Forgot password?"
  };

  return (
    <div className="signin-popup-overlay">
      <div className="signin-popup" ref={popupRef}>
        {/* Logo and Title */}
        <div className="signin-popup-header">
          <LittlePetExpressLogo className="signin-popup-logo" />
        </div>

        {/* Sign In Form */}
        <div className="signin-popup-content">
          <h2>Sign in</h2>
          <form className="signin-popup-form">
            {/* Email Address Input */}
            <div className="signin-email-address-input-container">
              <label htmlFor="email" className="signin-popup-label">
                Email Address
              </label>
              <input
                type="email"
                id="email"
                className="signin-popup-input"
              />
            </div>
            {/* Show "Forgot email?" only if not in password step */}
            {!isPasswordStep && (
              <div className="forgot-login-credentials-container">
                <div className="forgot-email">Forgot email?</div>
              </div>
            )}

            {/* Password Input (Shown After Continue) */}
            {isPasswordStep && (
              <>
                <div className="signin-password-input-container">
                  <label htmlFor="password" className="signin-popup-label">
                    Password
                  </label>
                  <input
                    type="password"
                    id="password"
                    className="signin-popup-input"
                  />
                </div>
                <div className="forgot-login-credentials-container">
                  <div className="forgot-password">Forgot password?</div>
                </div>
              </>
            )}
            <button
              type="submit"
              className="signin-popup-continue-btn"
              onClick={handleContinue}
            >
              Continue
            </button>
          </form>

          {/* Divider */}
          <div className="signin-popup-divider">
            <span className="line"></span>
            <span className="or-text">or</span>
            <span className="line"></span>
          </div>

          {/* Social Buttons */}
          <button className="signin-popup-social-btn google-btn">
            <span className="google-logo"></span> Continue with Google
          </button>
          <button className="signin-popup-social-btn apple-btn">
            <span className="apple-logo"></span> Continue with Apple
          </button>
        </div>
      </div>
    </div>
  );
};

export default SignInPopup;
