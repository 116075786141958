import * as React from "react"
const BoxieCatProbioticIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    viewBox="0 0 194 186"
    {...props}
  >
    <defs>
      <style>
        {
          ".boxiecat-probiotic0{stroke:#d80505;stroke-width:4px;fill:none;fill-rule:evenodd;stroke-miterlimit:10}"
        }
      </style>
    </defs>
    <path
      d="M82.5 15.5c38.7-2.9 66.2 13.1 82.5 48 11.2 38.1.7 68.6-31.5 91.5-31.5 16.3-61.1 13.6-89-8C19.7 122.8 13.2 94.3 25 61.5c11.6-24.8 30.8-40.1 57.5-46Z"
      style={{
        stroke: "#868d93",
        strokeWidth: 5,
        fill: "none",
        fillRule: "evenodd",
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="M76.5 38.5c9.4.3 18.8 1.4 28 3.5 1.8.8 3.3 2 4.5 3.5 2.2 8.1-.8 12.8-9 14-7.4-1.5-14.9-2.6-22.5-3.5-6.3-5.5-6.6-11.3-1-17.5ZM120.4 39.3c2.7-.2 5.4 0 8 .5 8.4 4.1 15.9 9.2 22.5 15.5.7 9-3.5 12.8-12.5 11.5-7.8-4-15-8.8-21.5-14.5-1.5-5.2-.3-9.5 3.5-13ZM55.1 41.9c7.1-1.3 11.5 1.5 13 8.5-2.6 8.7-5.7 17.2-9.5 25.5-8.5 5.2-13.7 2.8-15.5-7 1.7-6.8 3.8-13.5 6.5-20 1-3.1 2.8-5.5 5.5-7ZM110.5 59.5c9.4-.5 13.2 3.9 11.5 13-5.1 6.8-10.6 13.3-16.5 19.5-9.3 2.6-13.8-.9-13.5-10.5 5.3-8.1 11.5-15.5 18.5-22ZM38.1 82.2c5.5-.3 9.4 2 11.5 7 2.1 7.1 3.6 14.2 4.5 21.5-1 8.8-5.6 11.5-14 8-2.3-2.8-3.8-5.9-4.5-9.5-1.7-5.7-2.8-11.6-3.5-17.5.2-4.4 2.2-7.5 6-9.5ZM138.5 88.5c4.8-.4 9.5.1 14 1.5 5.2 7.5 3.5 12.9-5 16-5.4.6-10.8 1.6-16 3-11.6 1.2-15.6-3.6-12-14.5 1.6-1.8 3.6-3 6-3.5 4.5-.4 8.9-1.3 13-2.5ZM70.4 94.1c9.9.4 19.5 1.9 29 4.5 5.2 5 5.2 10 0 15-2.1.9-4.2 1.4-6.5 1.5-7.6-.8-15.1-2-22.5-3.5-5.2-3.8-6.3-8.6-3.5-14.5 1.1-1.1 2.3-2.1 3.5-3ZM60.5 116.5c3.1-.3 6.1.2 9 1.5 6.1 4.5 12 9.3 17.5 14.5 3.3 7.7 1 12.7-7 15-2.3-.4-4.5-1.2-6.5-2.5-5.2-4.7-10.5-9.2-16-13.5-3.3-6-2.3-11 3-15ZM116.5 116.5c3.3-.2 6.7 0 10 .5 7.9 5.6 7.9 11.2 0 17-7.5 1.1-15 2.3-22.5 3.5-7.7-1-10.7-5.3-9-13 .8-1 1.7-2 2.5-3 6.3-2.2 12.6-3.8 19-5Z"
      className="boxiecat-probiotic0"
    />
  </svg>
)
export default BoxieCatProbioticIcon
