// src/components/ProductCollection.js
import React from 'react';
import './ProductCollection.css'; // Import CSS for styling
import { FaChevronLeft, FaChevronRight, FaImage } from 'react-icons/fa'; // Import scroll icons

const ProductCollection = ({ title, products }) => {
  // Placeholder image URL (you can replace this with your own or use an icon)
  const placeholderImage = <FaImage className="placeholder-icon" />;

  return (
    <div className="product-collection-wrapper">
      <h2 className="collection-title">{title}</h2>
      <div className="scroll-button left">
        <FaChevronLeft />
      </div>
      <div className="product-collection">
        {products.map((product) => (
          <div className="product-card" key={product.id}>
            {/* Visible Box for Image and Name */}
            <div className="product-visible-box">
              <div className="product-image">
                {product.image ? (
                  <img src={product.image} alt={product.name} />
                ) : (
                  placeholderImage // Show placeholder icon if no image is provided
                )}
              </div>
              <p className="product-name">{product.name}</p>
            </div>
            {/* Product Details Outside the Visible Box */}
            <div className="product-details">
              <p className="brand-name">{product.brand}</p>
              <p className="product-description">
                Product Name may occupy two lines
              </p>
              <div className="price-section">
                <span className="price">${product.price}</span>
              </div>
              <div className="hidden-controls">
                  <div className="quantity-controls">
                    <button className="decrement">-</button>
                    <input type="text" value="1" readOnly className="quantity" />
                    <button className="increment">+</button>
                  </div>
                  <button className="add-to-cart-button">Add to Cart</button>
                  <button className="buy-now-button">Buy Now</button>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="scroll-button right">
        <FaChevronRight />
      </div>
    </div>
  );
};

export default ProductCollection;
