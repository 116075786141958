// src/components/FullHorizontalAd.js
import React from 'react';
import './FullHorizontalAd.css';
import sampleImage from '../assets/dog-ad-image.png'; // Import your image

const FullHorizontalAd = () => {
  return (
    <div className="full-horizontal-ad">
      <div className="ad-content">
        <h1 className="ad-title">Ad for some products</h1>
        <p className="ad-description">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eleifend, purus quis iaculis rutrum, Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eleifend, purus quis iaculis rutrum, Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eleifend, purus quis iaculis rutrum, Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eleifend, purus quis iaculis rutrum,
        </p>
        <button className="shop-now-button">Shop Now</button>
      </div>
      <div className="ad-image-container">
        <img src={sampleImage} alt="Ad for products" className="ad-image" />
      </div>
    </div>
  );
};

export default FullHorizontalAd;
