import React from "react";
import "./ExpertSupport.css";
import SupportIcon from "./SupportIcon"; // Your SupportIcon component
import ArrowIcon from "./ArrowIcon"; // Your ArrowIcon component
import FloatingContainer from "./FloatingContainer";
import { FaEnvelope } from 'react-icons/fa';
import ChatIcon from "./ChatIcon";
import { VscCallOutgoing } from "react-icons/vsc";

const ExpertSupport = () => {
  return (
    <div className="expert-support-container">
      {/* Triggering div */}
      <div className="expert-support">
        <SupportIcon width="20px" height="20px" color="#ec0200" className="icon" />
        <span>Support <ArrowIcon /></span>
      </div>

      {/* Floating container */}
      <FloatingContainer customTriangleOffset="64.3%" leftPosition="-100px">
        <div className="floating-content">
          <p className="help-text">Get help from our experts during <br /> business hours</p>
          <div className="phone-number-wrapper">
      <div className="phone-icon">
        <VscCallOutgoing className="call-icon" />
      </div>
      <a className="phone-number" href="tel:+17072939629">+1 (707) 293-9629</a>
    </div>
          <p className="availability">Not Available</p>
          <div className="contact-options">
            <button className="chat-live-button"><ChatIcon className="chat-icon" /> Chat Live</button>
            <a href="mailto:support@littlepetexpress.com" className="contact-us-button"><FaEnvelope className="email-icon" /> Contact us</a>
          </div>
          <div className="expert-support-footer-links">
            <a href="#">Track Order</a>
            <span>—</span>
            <a href="#">FAQs</a>
            <span>—</span>
            <a href="#">Business Hours</a>
          </div>
        </div>
      </FloatingContainer>
    </div>
  );
};

export default ExpertSupport;
