//// src/components/DogCollections.js
//import React, { useRef } from 'react';
//import './DogCollections.css'; // Import CSS for styling
//import { FaChevronLeft, FaChevronRight, FaImage } from 'react-icons/fa'; // Import icons for scrolling and placeholder
//
//const DogCollections = () => {
//  // Sample collection data with some items missing images
//  const collectionItems = [
//    { id: 1, name: 'Dry Food', image: '' },
//    { id: 2, name: 'Wet Food', image: '' },
//    { id: 3, name: 'Treats', image: '' },
//    { id: 4, name: 'Toys', image: '' },
//    { id: 5, name: 'Harnesses', image: '' },
//    { id: 6, name: 'Collars', image: '' },
//    { id: 7, name: 'Leashes', image: '' },
//    { id: 8, name: 'Grooming', image: '' },
//    { id: 9, name: 'Travel', image: '' },
//    { id: 10, name: 'Training', image: '' },
//  ];
//
//  const containerRef = useRef(null);
//
//  // Function to scroll to the next set of items
//  const handleNextClick = () => {
//    if (containerRef.current) {
//      containerRef.current.scrollBy({ left: 300, behavior: 'smooth' });
//    }
//  };
//
//  // Function to scroll to the previous set of items
//  const handlePrevClick = () => {
//    if (containerRef.current) {
//      containerRef.current.scrollBy({ left: -300, behavior: 'smooth' });
//    }
//  };
//
//  // Placeholder icon for items without images
//  const placeholderImage = <FaImage className="placeholder-icon" />;
//
//  return (
//    <div className="dog-collections-wrapper">
//      <h2 className="collection-title">DOG COLLECTIONS</h2>
//      <div className="scroll-container">
//        <button className="scroll-button left" onClick={handlePrevClick}>
//          <FaChevronLeft />
//        </button>
//        <div className="dog-collections" ref={containerRef}>
//          {collectionItems.map((item) => (
//            <div className="dog-collection-item" key={item.id}>
//              <div className="dog-collection-image-container">
//                  {item.image ? (
//                        <img
//                          src={item.image}
//                          alt={item.name}
//                          className="dog-collection-image"
//                        />
//                  ) : (
//                    placeholderImage // Fallback placeholder icon if no image is provided
//                  )}
//              </div>
//              <p className="dog-collection-name">{item.name}</p>
//            </div>
//          ))}
//        </div>
//        <button className="scroll-button right" onClick={handleNextClick}>
//          <FaChevronRight />
//        </button>
//      </div>
//    </div>
//  );
//};
//
//export default DogCollections;


import React from 'react';
import PetCollections from './PetCollections';

const dogCollectionItems = [
  { id: 1, name: 'Dry Food', image: '' },
  { id: 2, name: 'Wet Food', image: '' },
  { id: 3, name: 'Treats', image: '' },
  { id: 4, name: 'Toys', image: '' },
  { id: 5, name: 'Harnesses', image: '' },
  { id: 6, name: 'Collars', image: '' },
  { id: 7, name: 'Leashes', image: '' },
  { id: 8, name: 'Grooming', image: '' },
  { id: 9, name: 'Travel', image: '' },
  { id: 10, name: 'Training', image: '' },
];

const DogCollections = () => (
  <PetCollections title="Dog Collections" collectionItems={dogCollectionItems} />
);

export default DogCollections;

