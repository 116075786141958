import React, { useState } from "react";
import "./NZNaturalDoubleFeatureAd.css";
import NZNaturalLogo from "./NZNaturalLogo";

const NZNaturalDoubleFeatureAd = () => {
  // State for the selected recipe, size, and quantity
  const [selectedRecipeCat, setSelectedRecipeCat] = useState("Chicken & King Salmon");
  const [selectedSizeCat, setSelectedSizeCat] = useState("1.7-oz");
  const [quantityCat, setQuantityCat] = useState(1);

  const [selectedRecipeDog, setSelectedRecipeDog] = useState("Chicken");
  const [selectedSizeDog, setSelectedSizeDog] = useState("1.7-oz");
  const [quantityDog, setQuantityDog] = useState(1);

  const handleAddToCart = (type) => {
    alert(`Added ${type === "cat" ? selectedRecipeCat : selectedRecipeDog} to the cart!`);
  };

  return (
    <div className="nznatural-double-feature">
      {/* Left Section (Cat Product) */}
      <div className="nznatural-product-section-wrapper">
        <div className="nznatural-product-section-left-product">
          <div className="nznatural-red-background left-red-background"></div>
            <img
              src="https://cdn.thelittlepetshoppe.com/nznatural-double-feature-ad-meow.png"
              alt="Cat Product"
              className="nznatural-product-image"
            />
            <img
              src="https://cdn.thelittlepetshoppe.com/nznatural-double-feature-ad-cat.png"
              alt="Cat"
              className="secondary-image meow-cat"
            />
        </div>
        <div className="nznatural-title-description">
          <NZNaturalLogo className="nznatural-logo" />
          <img
              src="https://cdn.thelittlepetshoppe.com/nznatural-background-watermark-image.png"
              alt="NZ Natural Watermark"
              className="nznatural-description-image-background-watermark"
            />
          <h2>THE NEW ZEALAND NATURAL</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
            Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.
          </p>
        </div>
        <div className="nznatural-product-section-right-product">
        <div className="nznatural-red-background right-red-background"></div>
        <img
            src="https://cdn.thelittlepetshoppe.com/nznatural-double-feature-ad-woof.png"
            alt="Dog Product"
            className="nznatural-product-image"
          />
          <img
            src="https://cdn.thelittlepetshoppe.com/nznatural-double-feature-bowl.png"
            alt="Dog"
            className="secondary-image woof-bowl"
          />
      </div>
      </div>


      <div className="nznatural-product-content-wrapper">
        <div className="nznatural-product-content-meow">
          <div className="nznatural-product-info-meow">
            <h3>Recipes: <span>{selectedRecipeCat}</span></h3>
            <div className="recipes meow-recipes">
              {["Chicken & King Salmon", "Lamb & King Salmon", "Duck"].map((recipe) => (
                <button
                  key={recipe}
                  className={`recipe-button ${selectedRecipeCat === recipe ? "active" : ""}`}
                  onClick={() => setSelectedRecipeCat(recipe)}
                >
                  {recipe}
                </button>
              ))}
            </div>
            <h3>Size: <span>{selectedSizeCat}</span></h3>
            <div className="sizes-and-cart-meow">
              <div className="sizes meow-sizes">
                {["1.7-oz", "9.9-oz"].map((size) => (
                  <button
                    key={size}
                    className={`size-button ${selectedSizeCat === size ? "active" : ""}`}
                    onClick={() => setSelectedSizeCat(size)}
                  >
                    {size}
                  </button>
                ))}
              </div>
              <div className="nznatural-quantity">
                <button className="decrease" onClick={() => setQuantityCat(Math.max(1, quantityCat - 1))}>-</button>
                <span>{quantityCat}</span>
                <button className="increase" onClick={() => setQuantityCat(quantityCat + 1)}>+</button>
              </div>
              <button
                className="nznatural-add-to-cart-button"
                onClick={() => handleAddToCart("cat")}
              >
                Add to cart
              </button>
            </div>
          </div>
        </div>
        <div className="nznatural-product-content-woof">
          <div className="nznatural-product-info-woof">
            <h3>Recipes: <span>{selectedRecipeDog}</span></h3>
            <div className="recipes woof-recipes">
              {["Chicken", "Lamb", "Duck"].map((recipe) => (
                <button
                  key={recipe}
                  className={`recipe-button ${selectedRecipeDog === recipe ? "active" : ""}`}
                  onClick={() => setSelectedRecipeDog(recipe)}
                >
                  {recipe}
                </button>
              ))}
            </div>
            <h3>Size: <span>{selectedSizeDog}</span></h3>
            <div className="sizes-and-cart-woof">
              <div className="nznatural-quantity">
                <button className="decrease" onClick={() => setQuantityDog(Math.max(1, quantityDog - 1))}>-</button>
                <span>{quantityDog}</span>
                <button className="increase" onClick={() => setQuantityDog(quantityDog + 1)}>+</button>
              </div>
              <button
                className="nznatural-add-to-cart-button"
                onClick={() => handleAddToCart("dog")}
              >
                Add to cart
              </button>
              <div className="sizes woof-sizes">
                {["1.7-oz", "9.9-oz", "2.2-lb"].map((size) => (
                  <button
                    key={size}
                    className={`size-button ${selectedSizeDog === size ? "active" : ""}`}
                    onClick={() => setSelectedSizeDog(size)}
                  >
                    {size}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NZNaturalDoubleFeatureAd;
