import React from "react";
import "./HomePageAboutUsSection.css";
import { FaDog, FaPaw, FaUtensils, FaBone } from "react-icons/fa";

const HomePageAboutUsSection = () => {
  return (
    <div className="homepage-about-us-section">
      {/* Background image */}
      <div className="homepage-about-us-background-image">
        <img
          src="https://cdn.thelittlepetshoppe.com/dog-in-a-field.png"
          alt="Dog in the field"
        />
      </div>

      {/* Content */}
      <div className="homepage-about-us-content">
        <h2>ABOUT US</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
          ultricies eleifend augue id congue. Donec posuere luctus augue, et
          lobortis mauris suscipit vel. Nulla sed metus rhoncus, imperdiet ex.
        </p>
      </div>

      {/* Wave pattern */}
      <div className="homepage-about-us-wave-pattern"></div>

      {/* Features */}
      <div className="homepage-about-us-features">
        <div className="homepage-about-us-feature-item">
          <div className="homepage-about-us-icon">
            <FaDog />
          </div>
          <div className="homepage-about-us-feature-content">
            <p>Lorem ipsum dolor sit amet, consect</p>
            <button className="homepage-about-us-learn-more-btn">
              Learn more
            </button>
          </div>
        </div>
        <div className="homepage-about-us-feature-item">
          <div className="homepage-about-us-icon">
            <FaPaw />
          </div>
          <div className="homepage-about-us-feature-content">
            <p>Lorem ipsum dolor sit amet, consect</p>
            <button className="homepage-about-us-learn-more-btn">
              Learn more
            </button>
          </div>
        </div>
        <div className="homepage-about-us-feature-item">
          <div className="homepage-about-us-icon">
            <FaUtensils />
          </div>
          <div className="homepage-about-us-feature-content">
            <p>Lorem ipsum dolor sit amet, consect</p>
            <button className="homepage-about-us-learn-more-btn">
              Learn more
            </button>
          </div>
        </div>
        <div className="homepage-about-us-feature-item">
          <div className="homepage-about-us-icon">
            <FaBone />
          </div>
          <div className="homepage-about-us-feature-content">
            <p>Lorem ipsum dolor sit amet, consect</p>
            <button className="homepage-about-us-learn-more-btn">
              Learn more
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePageAboutUsSection;
