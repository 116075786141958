import * as React from "react";
const SignInIcon = ({ width = "24px", height = "24px", className = "" }) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40.91 40.68"
    width={width}
    height={height}
    className={className}
  >
    <defs>
      <style>
        {
          "\n      .circle {\n        fill: #ea0001;\n      }\n\n      .stroke {\n        fill: #fff;\n      }\n    "
        }
      </style>
    </defs>
    <circle className="circle" cx={20.5} cy={20.4} r={19.9} />
    <g>
      <path
        className="stroke"
        d="M28.69,28.01c-.22-.7-.34-1.35-.62-1.93-1.31-2.82-3.53-4.34-6.65-4.42-1.38-.03-2.79-.06-4.13.19-3.29.62-5.64,3.57-5.76,6.93-.01.31-.13.73-.36.89-.24.17-.67.19-.99.12-.4-.09-.54-.47-.54-.88.01-2.21.72-4.18,2.15-5.86,1.76-2.08,4.02-3.23,6.76-3.29,1.58-.03,3.21-.07,4.75.22,4.17.78,7.22,4.56,7.27,8.8.01.76-.34,1.14-1.11,1.14-4.94,0-9.87,0-14.81,0-.16,0-.33-.02-.48-.07-.42-.14-.61-.45-.61-.89,0-.43.19-.76.61-.89.22-.07.47-.07.7-.07,4.39,0,8.77,0,13.16,0,.17,0,.35,0,.64,0Z"
      />
      <path
        className="stroke"
        d="M20.12,18.18c-2.89,0-5.11-2.21-5.09-5.07.02-2.86,2.21-5.02,5.09-5.02,2.9,0,5.1,2.19,5.09,5.06-.01,2.86-2.21,5.03-5.08,5.03ZM20.12,9.95c-1.8,0-3.14,1.35-3.14,3.17,0,1.83,1.36,3.2,3.18,3.19,1.79-.01,3.14-1.39,3.12-3.2-.01-1.82-1.36-3.16-3.16-3.16Z"
      />
      <path
        className="stroke"
        d="M29.41,17.07c1.05,0,2.1,0,3.15,0,.22,0,.45,0,.67.01.57.05.9.41.89.96-.01.53-.37.91-.94.93-.91.02-1.81,0-2.72.01-.36,0-.71,0-1.18,0,.32.33.57.6.83.86.48.5.51,1.03.08,1.44-.42.41-.93.38-1.41-.09-.8-.8-1.6-1.59-2.39-2.4-.51-.52-.51-1.03.01-1.55.79-.8,1.59-1.6,2.39-2.39.47-.47,1-.49,1.41-.08.42.42.41.92-.06,1.41-.26.27-.54.5-.81.75.02.04.05.09.07.13Z"
      />
    </g>
  </svg>
);
export default SignInIcon;
