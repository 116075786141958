import React from "react";
import "./BoxieCatFeatureSection.css";
import BoxieCatProductDisplay from "./BoxieCatProductDisplay";
import BoxieCatProductFeatures from "./BoxieCatProductFeatures";
import BoxieCatBadgeShape from "./BoxieCatBadgeShape";

const BoxieCatFeatureSection = () => {
  return (
    <div className="boxiecat-card">
      {/* Left */}
      <div className="boxiecat-feature-left">
        <BoxieCatProductDisplay />
      </div>

      {/* Center */}
      <div className="boxiecat-feature-center">
        <BoxieCatProductFeatures />
      </div>

      {/* Right */}
      <div className="boxiecat-feature-right">
        <img
            src="https://cdn.thelittlepetshoppe.com/boxiecat-upsidedown-cat.png"
            className="boxiecat-upsidedown-cat"
        />
      </div>
        <div className="boxiecat-feature-attributes-wrapper">
            <div className="boxiecat-feature-attribute product-name">
                BOXIECAT PRO AIR LITTER, <span className="no-bold">6.6-lb</span>
            </div>
            <div className="boxiecat-feature-attribute product-price">
                $31.59
            </div>
            <div className="boxiecat-feature-add-to-cart-wrapper">
                <button className="boxiecat-feature-quantity">
                    <span className="quantity-decrease">-</span>
                    <span className="quantity-select">1</span>
                    <span className="quantity-increase">+</span>
                </button>
                <button className="boxiecat-feature-cart add-to-cart">
                    Add to Cart
                </button>
            </div>
        </div>
        <div className="boxiecat-badge-container">
            <BoxieCatBadgeShape className="boxiecat-badge" />
            <div className="boxiecat-as-seen-in">AS SEEN IN</div>
            <div className="boxiecat-forbes">Forbes</div>
        </div>
    </div>
  );
};

export default BoxieCatFeatureSection;
