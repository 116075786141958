import React from 'react';
import './BottomBar.css'; // Import CSS for styling
import { FaPhoneAlt, FaEnvelope, FaArrowUp } from 'react-icons/fa'; // Import icons

const BottomBar = () => {
  // Function to handle scroll to top
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="page-bottom-bar">
      <p className="bottom-bar-text">Our experts are available</p>
      {/* Clickable phone link */}
      <a href="tel:+17072939629" className="contact-item phone">
        <FaPhoneAlt className="icon" />
        <span>+1 (707) 293-9629</span>
      </a>

      {/* Clickable email link */}
      <a href="mailto:support@littlepetexpress.com" className="contact-item email">
        <FaEnvelope className="icon" />
        <span>support@littlepetexpress.com</span>
      </a>
      <button className="back-to-top-button" onClick={scrollToTop}>
        <FaArrowUp className="icon" />
        BACK TO TOP
      </button>
    </div>
  );
};

export default BottomBar;
