// src/components/BrandCollection.js
import React, { useRef } from 'react';
import './BrandCollection.css'; // Import CSS for styling
import { FaChevronLeft, FaChevronRight, FaImage } from 'react-icons/fa'; // Import icons for scrolling and placeholder

const BrandCollection = () => {
  // Sample collection data with some items missing images
  const collectionItems = [
    { id: 1, name: 'Brand A', image: '' },
    { id: 2, name: 'Brand B', image: '' },
    { id: 3, name: 'Brand C', image: '' },
    { id: 4, name: 'Brand D', image: '' },
    { id: 5, name: 'Brand E', image: '' },
    { id: 6, name: 'Brand F', image: '' },
    { id: 7, name: 'Brand G', image: '' },
  ];

  const containerRef = useRef(null);

  // Function to scroll to the next set of items
  const handleNextClick = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: 300, behavior: 'smooth' });
    }
  };

  // Function to scroll to the previous set of items
  const handlePrevClick = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: -300, behavior: 'smooth' });
    }
  };

  // Placeholder icon for items without images
  const placeholderImage = <FaImage className="placeholder-icon" />;

  return (
    <div className="brand-collection-wrapper">
      <h2 className="collection-title">Brands Our Customers Love</h2>
      <div className="scroll-container">
        <button className="scroll-button left" onClick={handlePrevClick}>
          <FaChevronLeft />
        </button>
        <div className="brand-collection" ref={containerRef}>
          {collectionItems.map((item) => (
            <div className="brand-collection-item" key={item.id}>
              <div className="brand-collection-image-container">
                  {item.image ? (
                        <img
                          src={item.image}
                          alt={item.name}
                          className="brand-collection-image"
                        />
                  ) : (
                    placeholderImage // Fallback placeholder icon if no image is provided
                  )}
              </div>
              <p className="brand-collection-name">{item.name}</p>
            </div>
          ))}
        </div>
        <button className="scroll-button right" onClick={handleNextClick}>
          <FaChevronRight />
        </button>
      </div>
    </div>
  );
};

export default BrandCollection;



