// src/components/StaffsPick.js
import React from 'react';
import './StaffsPick.css'; // Import the CSS for styling
import { FaChevronLeft, FaChevronRight, FaStar, FaStarHalfAlt, FaRegStar, FaHeart } from 'react-icons/fa'; // Import icons
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import sampleImage from '../assets/Earth Animal No Hide Roll.png'; // Sample image for product
import sampleDogImage from '../assets/dog-image.jpg';
import LittlePetExpressLogo from './LittlePetExpressLogo'

const StaffsPick = () => {
  const products = [
    {
      id: 1,
      image: sampleImage,
      title: "Lorem Ipsum is simply dummy text of the printing and typesetting",
      rating: 4.7,
      reviews: 57,
      tag: "Deal",
      author: "Little Pet Express",
    },
    // Additional products...
    {
      id: 2,
      image: sampleImage,
      title: "Lorem Ipsum is simply dummy text of the printing and typesetting",
      rating: 4.7,
      reviews: 57,
      tag: "Best Seller",
      author: "Little Pet Express",
    },
    {
      id: 3,
      image: sampleImage,
      title: "Lorem Ipsum is simply dummy text of the printing and typesetting",
      rating: 4.7,
      reviews: 57,
      tag: "Deal",
      author: "Little Pet Express",
    },
    {
      id: 4,
      image: sampleImage,
      title: "Lorem Ipsum is simply dummy text of the printing and typesetting",
      rating: 4.7,
      reviews: 57,
      tag: "Best Seller",
      author: "Little Pet Express",
    },
    {
      id: 5,
      image: sampleImage,
      title: "Lorem Ipsum is simply dummy text of the printing and typesetting",
      rating: 4.7,
      reviews: 57,
      tag: "Deal",
      author: "Little Pet Express",
    },
    {
      id: 6,
      image: sampleImage,
      title: "Lorem Ipsum is simply dummy text of the printing and typesetting",
      rating: 4.7,
      reviews: 57,
      tag: "Best Seller",
      author: "Little Pet Express",
    },
    {
      id: 7,
      image: sampleImage,
      title: "Lorem Ipsum is simply dummy text of the printing and typesetting",
      rating: 4.7,
      reviews: 57,
      tag: "Deal",
      author: "Little Pet Express",
    },
    {
      id: 8,
      image: sampleImage,
      title: "Lorem Ipsum is simply dummy text of the printing and typesetting",
      rating: 4.7,
      reviews: 57,
      tag: "Best Seller",
      author: "Little Pet Express",
    },
    {
      id: 9,
      image: sampleImage,
      title: "Lorem Ipsum is simply dummy text of the printing and typesetting",
      rating: 4.7,
      reviews: 57,
      tag: "Deal",
      author: "Little Pet Express",
    },
    {
      id: 10,
      image: sampleImage,
      title: "Lorem Ipsum is simply dummy text of the printing and typesetting",
      rating: 4.7,
      reviews: 57,
      tag: "Best Seller",
      author: "Little Pet Express",
    }
  ];

  // Function to render stars based on the rating
  const renderStars = (rating) => {
    const fullStars = Math.floor(rating); // Number of full stars
    const halfStar = rating % 1 >= 0.5 ? 1 : 0; // Check if there should be a half star
    const emptyStars = 5 - fullStars - halfStar; // Remaining stars are empty

    return (
      <>
        {Array(fullStars)
          .fill()
          .map((_, index) => (
            <FaStar key={`full-${index}`} className="star-icon" />
          ))}
        {halfStar === 1 && <FaStarHalfAlt className="star-icon" />}
        {Array(emptyStars)
          .fill()
          .map((_, index) => (
            <FaRegStar key={`empty-${index}`} className="star-icon" />
          ))}
      </>
    );
  };

  return (
    <div className="staffs-pick-container">
      <h2 className="staffs-pick-title">STAFF'S PICKS</h2>
      <a href="#" className="shop-all-link">Shop all</a>

      <div className="staffs-pick-wrapper">
          {/* First Item: Image and Description */}
          <div className="first-item">
            <img src={sampleDogImage} alt="Holiday travel" className="first-item-image" />
            <div className="first-item-description">
              <h3 className="first-item-title">Holiday travel essentials for your pet</h3>
              <p className="first-item-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
            </div>
          </div>

          {/* Scrollable Product Collection */}
          <div className="product-collection-wrapper">
            <button className="scroll-button left"><FaChevronLeft /></button>
            <div className="product-collection">
              {products.map((product) => (
                <div className="product-card" key={product.id}>
                  {/* Conditional rendering for the tag */}
                  {product.tag && (
                    <div
                      className={
                        product.tag === "Deal" ? "deal-flag" : product.tag === "Best Seller" ? "bestseller-flag" : ""
                      }
                    >
                      {product.tag} {/* Render the tag text */}
                    </div>
                  )}
                  <div className="product-image-container">
                    <img src={product.image} alt="Product" className="product-image" />
                  </div>
                  <div className="product-content">
                    <h4 className="product-title">{product.title}</h4>
                    <div className="product-author">
                        By <LittlePetExpressLogo className="author-logo" />
                    </div>
                    <div className="product-rating">
                      {product.rating} {renderStars(product.rating)} <span>({product.reviews})</span>
                    </div>
                    <div className="product-save">
                      <LocalOfferIcon className="save-icon" /> <b>Save </b> Lorem Ipsum
                    </div>
                  </div>
                  <div className="hidden-controls">
                      <div className="quantity-controls">
                        <button className="decrement">-</button>
                        <input type="text" value="1" readOnly className="quantity" />
                        <button className="increment">+</button>
                      </div>
                      <button className="add-to-cart-button">Add to Cart</button>
                      <button className="buy-now-button">Buy Now</button>
                  </div>
                </div>
              ))}
            </div>
            <button className="scroll-button right"><FaChevronRight /></button>
          </div>
      </div>
    </div>
  );
};

export default StaffsPick;
