import * as React from "react"
const NZNaturalLogo = ({className}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    viewBox="0 0 356 350"
    className={className}
  >
    <defs>
      <style>
        {
          ".nznatural-logo-2{fill:#0a0a0c}.nznatural-logo-4{fill:#09090b}.nznatural-logo-8{fill:#08090b}.nznatural-logo-10{fill:#f9f9f9}.nznatural-logo-11{fill:#101012}.nznatural-logo-12{fill:#fafafa}.nznatural-logo-24{fill:#090a0c}.nznatural-logo-28{fill:#111113}"
        }
      </style>
    </defs>
    <path
      d="M191 .03c1.8.61 2.24 2.29 1.34 5.02 9.87-.49 13.73 1.83 11.58 6.98a257.645 257.645 0 0 0-16.23 29.12c-3.38 4.47 10.57 5.19 13.56 4.88 10.47-1.09-2.41-3.57-6.91-3.13-1.81-.51-2.63-2.06-2.24-3.9l16.62-29.98.45-5.18-13.37-1.06c-1.37-.22-1.24-1.4-.78-2.75h161v131.99c-2.23 1.24-3.65-.75-4.26-5.95-2.08-.39-38.93 10.43-40.27 12.08-1.45 1.79 2.83 13.64 7.47 14.87 5.26 1.39 29.47-5.99 34.92-9.81l2.14.81V350h-173l.22-2.33c9.18-3.73 6.93-34.9 3.59-40.54-2.83-4.77-13.55-3.53-15.72 2.4-2.31 6.3-3.49 34.21 4.45 37.93l-.54 2.54H0V152.02c1.01-1.5 2.76-1.77 5.25-.8.28-4.31 1.8-6.67 4.55-7.09l34.09 8.64c2.42 1.03 2.63-.67 2.15-2.83a297.582 297.582 0 0 1-37.48-10.25c1.64-5.6.93-7.99-2.12-7.17l-4.16 16.61-2.29.9V.03h191Z"
      style={{
        fill: "#fefefe",
      }}
    />
    <path
      d="M183 350h-8c-1.14-2.13-3.58-2.47-4.89-4.61-3.41-5.59-2.35-28.77-1.07-35.84 1.42-7.82 13.95-9.59 18.99-4.08 4.13 4.52 3.64 27.32 2.7 33.77-.79 5.46-2.66 8.48-7.74 10.76Z"
      className="nznatural-logo-24"
    />
    <path
      d="M356 132.02v12c-2.4 2.08-4.19 3.18-7.22 4.28-5.28 1.91-25.56 7.37-30.21 6.67-7-1.06-9.31-13.13-9.14-18.99l42.14-11.95c3.55.07 1.96 6.17 4.43 7.99Z"
      className="nznatural-logo-8"
    />
    <path
      d="M195 .03c.18.01.78.83 1.6.91 2.3.23 13.89.58 14.4 1.08.28.28.18 6.68-.56 7.95l-16.47 29.54c-.22 2.08.13 1.3 1.5 1.5 1.65.23 11.1.61 11.51 1.01-.07 1.5.4 3.84-.44 5.06-1.49 2.18-18.87.17-21.64-1.7-1.2-6.26 14.75-29.12 18.07-35.87.31-2.18-.25-1.2-1.51-1.44-3.65-.7-7.75-1.08-11.48-1.04l1-6.99c1.32.07 2.69-.1 4 0Z"
      className="nznatural-logo-24"
    />
    <path
      d="m0 150.02 5.01-20c8.24 1.19 7 1.87 4.99 8.99l39.01 9.59c-.8 1.69-.78 5.83-2.57 6.3-3.67.97-31.65-8.6-37.92-8.9-1.65 6.9-.57 8.63-8.51 6.01v-2Z"
      className="nznatural-logo-2"
    />
    <path
      d="M216.99 158.02c-.34 4.43.45 8.74 1.5 13 3.13 12.74 19.46 43.43 26.86 55.13 5.28 8.35 10.6 8.78 13.39 20.61.25 1.08.04 10.88 2.52 7.03l4.72-9.77c2.74 7.57.32 15.03-7.29 18.19-6.25 2.59-29.89 3.48-37.2 2.81-1.57-.14-2.97-.76-4.49-1.02 1.25-6.56 8.65-4.65 13.51-4.99 1.44-.1 2.95.74 2.46-1.48-.74-3.43-7.9-5.33-8.8-8.21-1.22-3.9 1.85-9.28 1.34-10.35-1.04-2.23-7.28-3.82-9.52-4.96-2.2 7.18.94 24.96-6.64 28.87-2.43 1.25-9.3 2.39-10.26-.48-1.87-5.61 4.56-2.17 6.41-5.37 1.28-2.22.84-22.03.4-25.43-.66-5.07-4.33-9.87-4.92-15.08-1.19-10.64 4.34-17.93 2.91-27.91s-10.53-4.41-12.89-9.11c-1.2-2.38 3.49-1 2.99-1.98-2.45-2.25-6.57-3.38-5.99-7.5.43-.42 8.08.44 10.29-.21 2.03-.6 3.15-3.59 4.72-4.28 2.29-1.01 4.88 0 7-1 2.01-.95 3.4-6.96 6.98-6.51Z"
      style={{
        fill: "#060709",
      }}
    />
    <path
      d="M156 137.02c5.06.31 10.24-5.98 10.94-10.56s-1.22-17.65 5.59-17.4c7.01.25 1.8 16.34 1.49 20.48-1.26 17.13 7.37 5.45 8.7-5.29.48-3.87-.91-13.16 2.27-14.73 10.74-5.29 4.23 17.11 3.97 21.97-.06 1.15-.8 6.52 1.52 5.53 3.73-1.59 8.37-13.64 8.47-17.61.07-2.99-5.7-11.55 1.55-11.47 5.68.06 4.64 7.17 7.13 8.02 3.5 1.19 7.18-4.9 9.62.32 2.72 5.84-3.24 15.25-2.24 21.75 9.9-5.17 6.72-23.64 20.49-24.1 1.35-.05 10.05.67 10.86 1.18 6.39 4.05 1.24 7.58.62 12.39-.23 1.78-.5 11.55 1.52 11.5.42 0 5.52-5.29 5.92-6.06 3.19-6.04 1.4-14.4 7.32-21.67s18.34-4.06 17.07 4.04c-1.04 6.61-22.98 15.15-13.79 23.17 3.05 2.66 5.61 1.36 8.67-.26 1.5-.79 5.52-5.57 5.33-1.68-.52 10.53-19.57 13.64-23.53 2.5-4.2 4.75-12.58 11.82-15.01 1.97-7.36 6.3-13.52 7.96-19.48-.99-.92 4.64-7.43 8.2-11.07 4.55-4.63-4.65-.46-16.65-.43-22.52-1.78 1.3-3.4-.67-4.31 1.64-2.12 5.38.06 19.54-2.45 22.08-5.84 5.93-6.58-6.74-7.26-6.76-3.65 5.15-12.95 11.99-13.51 1.02-1.99.94-2.69 4.48-5.01 5.47-4.56 1.93-10.88-.28-12-5.47-6.71 5.76-14.42 10.76-15.99-2-.96-.99-7.51 10.33-12.99 7.54-1.3-.66-2.71-4.5-3.49-4.52-.62-.02-3.39 4.23-5.2 4.77-20.17 6-15.76-28.37 1.68-32.4 1.92-.44 3.27.27 4.5.1 2.58-.36 3.64-3.65 7 .55 3.11 3.89-.82 6.71-1.42 10.52s-.52 10.04 1.43 13.43c7.24-5.68 8.61-11.21 9.52-19.98-1.6-.01-3.94-.05-3.86-2.3.19-4.91 4.16-4.22 4.94-5.34.9-1.29 3.1-15.1 7.66-10.12 3.56 3.89-1.45 10.01-.75 10.75l5.99-1c2.21 9.79-5.79 5.92-6.94 7.06-.16.16-1.06 4.21-1.06 4.44v15.5Z"
      className="nznatural-logo-8"
    />
    <path
      d="M153.74 212.76c-1.4 1.38-5.51 1.28-5.69 4.7-.13 2.67 3.05 6.17 3.05 10.05 0 3.63-2.18 5.96-2.85 9.25-1.22 5.99-1.28 14.55-.05 20.54.32 1.56 4.25 5.08 1.28 5.7-8.79 1.85-2.88-20.57-9.01-22.97-4.82-1.89-.76 3.08-.48 4.51 1.48 7.55-4.62 8.54-5.6 12.41-.43 1.7 5.34 4.52.95 5.89-3.43 1.07-19.64.03-23.39-1.29-5.63-1.98-5.14-2.3-12.2-3.8-7.69-1.64-13.34-5.72-13.8-14.28-.39-7.38 6.34-17.52 13.02-10.96 0 1.77-10.13.93-8.91 11.89.98 8.72 10.14 11.85 17.92 10.6-3.24-9.96 3.44-28.13 12.69-32.81 3.62-1.83 12.97-2.76 14.42-4.58 2.02-2.54-.09-18.62 6.4-22.59 1.01 6.68 3.43 4.78 7.23 6.76 2.81 1.47 7.93 8.09 5.01 10.98Z"
      style={{
        fill: "#07080a",
      }}
    />
    <path
      d="m163.89 46.92-9.88 2.11c-1.08-4.71-2.52-10.67-4.55-15.01-.7-1.49.16-2.48-2.46-1.99-.09 3.85 4.25 17.33.3 18.8-1.02.38-7.94 1.46-8.27 1.16-.53-5.59-14.97-42.17-13.61-44.02.85-1.16 5.44-1.44 6.43-.8 1.15.74 7.29 22.65 8.44 26.06.36 1.05.22 3.02 1.68 2.79 1.33-4.56-4.06-29-2.87-30.87.41-.65 5.79-1.62 6.76-1l10.11 28.87-3.07-30.35c.23-1.23 4.85-2.16 6.15-1.22 1.86 1.33 3.55 40.01 4.82 45.47Z"
      className="nznatural-logo-24"
    />
    <path
      d="M102 94.02c.2-.3 5.91-1.7 6.99-2l1.01 54c-2.41-.29-6.94.91-8.52-.99-1.31-1.58-6.13-18.34-7.73-22.27-.55-1.36-1.22-4.51-2.75-4.75v28h-7v-42.5c0-.49 7.5-5.77 8.48-3.48L102 127c.88-4.56-1.17-31.28 0-33Z"
      className="nznatural-logo-4"
    />
    <path
      d="M337.88 89.15c.3.19 2.94 6.2 2.97 6.56.15 1.55-14.74 13.65-16.85 16.3 7.43-.42 13.76-6.21 20.46-9.02.4 0 3.01 5.1 2.12 6.05l-40.61 20.55c-.97-1.71-3.45-4.7-2.53-6.54l20.55-20.02-25.45 12.03c-.39 0-3.06-5.16-2.12-6.08l36.86-19.17c1.2-.53 3.51-1.35 4.6-.66ZM81 56.03c-2.67-2.12-3-1.79-1 1 4.55 6.63 9.35 13.03 13.9 19.66.19 2.16-4.07 4.06-5.88 2.87-2.47-5.53-26.02-32-26.05-35.04-.03-2.14 5.31-5.74 7.29-5.35 2.24.44 17.75 14.91 18.74 13.85L75.11 34.35l4.41-4.21 27.01 34.89c2.19 2.63-3.79 6.41-5.82 5.89-5.89-5.75-13.39-9.77-19.7-14.9Z"
      className="nznatural-logo-8"
    />
    <path
      d="M60.93 115.1c.7.96-1.89 6.91-2.5 6.97l-18.41-8.05-3.02 7.99L54.58 130c.51.84-2.13 5.71-3.18 5.95-3.23.74-34.86-17.48-41.4-17.93l2.6-7.07 18.38 8.08c-.34-2.12 3.95-6.36 2.6-8.05-1.54-1.92-17.3-6.28-17.46-8.43 1.27-1.77 1.85-5.48 4.33-5.53l40.48 18.08Z"
      className="nznatural-logo-2"
    />
    <path
      d="M221.07 296.08c6.91-2.26 15.37-9.58 21.96-2.61 1.97 2.09 12.95 26.26 13.58 29.49 1.81 9.31-10.11 14.08-17.64 14.61l-17.89-41.5Z"
      className="nznatural-logo-8"
    />
    <path
      d="M206.77 299.19c3.45-.4 7.56 1.22 9.46 4.09 2.6 3.9 8.4 30.6 7.72 35.16-1.25 8.38-16.18 10.62-20.38 3.5-1.65-2.79-7.13-26.91-7.35-30.73-.39-6.59 4.06-11.26 10.55-12.01Z"
      className="nznatural-logo-4"
    />
    <path
      d="M283.71 247.22c3.92-.88 5.67.07 8.96 1.61s22.6 15.23 24.32 17.68c6.49 9.18-2.76 20.92-13.73 16.72-2.87-1.1-23.62-16.33-25.28-18.72-4.04-5.85-1.12-15.75 5.71-17.29Z"
      className="nznatural-logo-24"
    />
    <path
      d="M36.73 201.19c6.95-1.46 8.02 6.49 11.75 9.83 3.99 3.58 11.7 2.93 10.34 11.23-1.44 8.8-9.48 6.27-14.63 7.44s-12.67 7.15-17.67.3c-4.23-5.8 2.18-10.63 3.99-15.96 1.37-4.04.24-11.58 6.22-12.84Z"
      style={{
        fill: "#08080a",
      }}
    />
    <path
      d="M320.74 201.17c8.26-1.48 7.63 7.66 9.2 12.4 1.74 5.26 6.67 8.62 4.85 14.74-.36 1.2-3.29 4.14-4.5 4.5-6.23 1.86-9.47-2.26-14.29-3.32-4.41-.97-12.09 1.29-13.79-6.19-2.01-8.87 3.42-7.76 8.29-11.28 4.08-2.94 5.85-10.07 10.23-10.85Z"
      className="nznatural-logo-4"
    />
    <path
      d="M256.99 69.03c-12.05-.97-2.66-10.16-4-11.96-.24-.32-6.96-2.95-7.34-2.88-2.27.43-4.78 5.88-5.21 5.9-.48.02-6.16-1.92-5.47-3.56l27.6-36.57c2.38 1.18 9.3 3.35 9.24 6.37l-14.82 42.69Z"
      className="nznatural-logo-24"
    />
    <path
      d="M318.84 63.17c.44.26 5.78 7.51 4.71 8.83l-30.04 34.02c-9.64-6.43.9-9.61.41-12.35-.11-.64-4.75-6.52-5.96-6.24-5.05 1.17-5.01 7.09-10.94-.91 11.23-6.57 22.82-14.74 34.31-20.67 1.71-.88 5.96-3.6 7.51-2.69Z"
      className="nznatural-logo-8"
    />
    <path
      d="M79.51 250.26c11.89 10.68 13.69 18.98-.99 27.77-12.86 7.7-14.3-4.58-17.87-3.94-1.11.2-8.97 7.56-11.13 8.93-3.17 2.02-6.19-3.3-4.59-5.54l34.59-27.23ZM290.87 286.14c.42.24 5.53 7.34 5.68 7.8 3.62 11.6-8.95 19.7-18.45 11.46-3.63-3.15-16-16.61-18.55-20.44-9.05-13.65 13.5-24.88 20.38-9.59l-4.43 4.62c-2.37-4.65-11.99-8.82-10.34-.71.4 1.98 18.87 22.18 20.54 22.62 2.43.64 4.55-1.92 4.33-4.32-.25-2.8-5.22-6.3-4.95-7.93.24-1.42 4.07-4.51 5.8-3.51Z"
      className="nznatural-logo-24"
    />
    <path
      d="M243.97 11.07c.28.31-.72 6.73-.98 6.97-.68.62-12.31-4.62-12.98-4.01L227 27.02l9 2.02-1.96 6.58-10.03-2.59c.94 2.72-4 11.51-2.96 12.94 2.28 3.16 15.17-1.01 9.95 10.06l-18.95-6.06 13-43.9c2.52.32 18.28 4.26 18.93 5Z"
      style={{
        fill: "#0b0b0d",
      }}
    />
    <path
      d="M53.01 93c2.92.13 8.53 6.92 10.51 6.78 1.77-.13 6.24-8.23 7.98-9.74.89 1.4 4.04 2.26 4.45 3.61.63 2.07-8.98 13.57-10.94 15.94l-36.86-25.7c-1.69-2.93 9.53-12.94 9.95-16.81 10.96 2.4 2.56 7.94-.98 13.45 1.35 1.09 8.81 7.29 9.54 7.41 2.49.44 4.57-6.41 7-6.81.96-.16 5.33 3.05 5.34 3.39.01.53-6.95 7.01-5.98 8.48Z"
      className="nznatural-logo-2"
    />
    <path
      d="M76.01 304.99c1.99.37 9.23 6.31 8.95 7.44-.08.31-3.42 4.96-4.43 4.56-3.13-1.23-10.61-10.34-14.33-12.47l28.82-35.08c.75-.66 1.22-.4 1.99 0 3.63 1.87 9.73 9.75 13.99 12.06-5.72 10.81-10.77-4.38-14.99-2.08-.47.26-6.83 7.69-6.92 8.22-.76 4.3 13.11 5.36 2.43 12.34l-6.86-5.91-8.6 9.47-.03 1.44Z"
      className="nznatural-logo-4"
    />
    <path
      d="M111.91 14.11c.07 1.39 2.67 5.13 2.09 5.91-.33.44-8.56 2.77-10.12 4.47-1.42 1.54 3.29 11.14 4.72 11.47s7.59-4.11 9.3-2.85c.2.14 1.99 4.2 1.98 4.44-.12 1.84-7.84 2.75-7.89 5.94-.02 1.03 4.18 10.63 4.98 11.12 3.19-.06 8.57-5.12 11.42-4.65.51.08 2.61 4.77 2.63 5.54.02.98-15.72 6.35-17.54 8.53L93.09 22.52c.09-1.24 17.64-9.25 18.81-8.4Z"
      className="nznatural-logo-24"
    />
    <path
      d="M165 309.01 152 307c-.02 9.65-6.84 14.52 7.01 15.01 1.24 10.86-9.44 3.46-11 4.99-.65.64-2.29 15.53-3.03 17.98-.5.48-6.42-.41-6.86-1.1-1.53-2.41 8.16-38.07 8.34-43.91 1.67-2.38 19.05 2.26 19.28 3.48.16.89-1.38 3.92-.75 5.56Z"
      className="nznatural-logo-4"
    />
    <path
      d="M131.87 293.16c.75 1.28-1.04 5.44-2.47 5.76-1.71.39-5.12-3.27-7.38-2.92l-18.03 35c-3.06-1.64-7.7-2.36-5.22-6.72l17.14-30.94c-3.21-5.07-8.19-2.18-3.88-10.34 2.71.92 19.29 9.19 19.85 10.15Z"
      className="nznatural-logo-2"
    />
    <path
      d="M289.64 37.14c1.02-.17 5.49 2.72 5.26 4.23-.51 3.4-21.74 25.28-24.89 30.16 1.22 2.14 8.58 6.07 8.73 7.99.09 1.1-2.49 4.63-4.06 4.38S261 73.36 261.14 71.71c3.56-4.07 26.29-34.2 28.5-34.56Z"
      style={{
        fill: "#0a0b0c",
      }}
    />
    <path
      d="M162.5 262.01c-.64-.16-1.3-1.03-1.07-2.03.19-.83 2.35-7.59 2.67-7.86.88-.76 22.35-.46 23.01.49 1.08 1.55 2.06 7.21 2.89 9.41-7.48-1.25-20.81 1.68-27.5 0Z"
      style={{
        fill: "#0a0b0d",
      }}
    />
    <path
      d="M37.7 177.17c8.37-2.01 15.75 16.07 7.63 18.74-8.36 2.74-13.94-17.22-7.63-18.74Z"
      style={{
        fill: "#0c0c0e",
      }}
    />
    <path
      d="M321.65 193.67c-7.73 7.69-14.15-5.14-6.67-13.17 10.72-11.51 13.53 6.36 6.67 13.17Z"
      style={{
        fill: "#0c0c0d",
      }}
    />
    <path
      d="M23.72 181.16c8.02-2.17 12.66 21.95 1.24 18.43-7.38-2.28-7.58-16.72-1.24-18.43Z"
      style={{
        fill: "#0d0d0e",
      }}
    />
    <path
      d="M330.29 198.71c-6.01-4.92 3.4-23.98 9.15-15.63 5.08 7.37-1.47 21.93-9.15 15.63Z"
      style={{
        fill: "#0d0d0f",
      }}
    />
    <path
      d="M55.59 194.1c4.3-1.37 5.71 9.56 2.88 12.88-3.36 3.93-8.72.95-8.48-3.36.09-1.65 4.31-9.1 5.6-9.51ZM302.01 194.01c9.61-.61 10.34 20.07 1.44 14.08-5.63-3.79-2-8.92-1.44-14.08Z"
      className="nznatural-logo-28"
    />
    <path
      d="M345 204.01c2.25 6.58-3.19 17.8-9.73 10.74-4.59-4.96 4.62-11.74 9.73-10.74ZM16.61 204.06c4.89-1.44 15.26 9.51 5.41 12.55-6.34 1.96-9.19-11.44-5.41-12.55Z"
      className="nznatural-logo-11"
    />
    <path
      d="M166 250.01c-1.7-1.48 9.08-7.1 10.5-7.11 1.15 0 2.2.28 3.07 1.05l5.44 6.05h-19Z"
      style={{
        fill: "#18191b",
      }}
    />
    <path
      d="m81 56.03-1 1c-.78-1.14-4-1.76-2.99-5 1.93.36 2.91 3.12 3.99 4Z"
      style={{
        fill: "#bfbfc0",
      }}
    />
    <path
      d="M176 311.5c.82-2.93 7-3.21 7-1v31c0 2.04-7 2.04-7 0 0-6.29-1.25-25.56 0-30Z"
      style={{
        fill: "#fcfcfc",
      }}
    />
    <path
      d="M348.89 133.11c1.43 3.65 2.08 5.53-1.63 7.68-1.71.99-22.69 7.02-24.88 7.2-4.44.36-6.52-5.3-5.36-6.92.96-1.33 27.88-7.09 31.87-7.95ZM132.99 118.03c-.63 4.9.34 23.25-8.28 20.86-7.51-2.09 1.19-20.96 8.28-20.86Z"
      className="nznatural-logo-10"
    />
    <path
      d="M240.99 119.03c.57 4.2-2.18 25.19-10.06 19.57-4.81-3.43 2.14-20.68 10.06-19.57Z"
      style={{
        fill: "#f8f8f8",
      }}
    />
    <path
      d="M272.99 112.03c.55.55-.33 3.44-1.45 4.04l-7.54 6.94c-.1-2.48 6.08-13.9 8.99-10.98Z"
      style={{
        fill: "#ededed",
      }}
    />
    <path
      d="M230 299.02c4.92-1.73 5.54-3.32 8.83 1.65 1.54 2.33 9.48 19.67 9.97 22.07 1.09 5.28-4.19 7.96-6.3 5.26L230 299.02Z"
      style={{
        fill: "#fbfbfb",
      }}
    />
    <path
      d="M215.76 338.74c-3.21 3.12-5.22.66-6.49-2.51-1.37-3.41-6.38-27.09-5.12-29.07.67-1.05 4.02-1.38 5.42-.26 1.77 1.41 6.73 24.46 6.99 28.08.09 1.3.23 2.76-.8 3.76ZM285.71 254.14c1.59-.39 2.39.45 3.62 1.03 1.79.84 20.16 13.66 21.2 14.8 2.56 2.82 1.55 6.77-2.08 7.08-3.13.27-20.26-14.22-24.51-16.48-1.98-2-.74-5.81 1.77-6.44Z"
      className="nznatural-logo-12"
    />
    <path
      d="M255.99 51.03 249 48.54c4.24-4.6 6.94-10.74 11.98-14.5l-5 17Z"
      style={{
        fill: "#f5f5f5",
      }}
    />
    <path
      d="M310 75.03c.44 1.88-1.07 2.37-1.99 3.5-2.95 3.61-6.2 7.17-9.5 10.48l-4.49-5.49c2.72-.69 14.36-9.98 15.99-8.49Z"
      style={{
        fill: "#f1f1f1",
      }}
    />
    <path
      d="M78.67 260.12c2.28-.2 3.8 3.77 2.86 5.85-.66 1.47-7.22 6.72-8.85 7.29-2.76.98-6.04-2.39-5.21-4.27.47-1.08 10.1-8.78 11.2-8.88Z"
      style={{
        fill: "#f7f7f7",
      }}
    />
  </svg>
)
export default NZNaturalLogo
