import * as React from "react";
const CartIcon = ({ width = "24px", height = "24px", className = "" }) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 38.56 39.28"
    width={width}
    height={height}
    className={className}
    preserveAspectRatio="xMinYMin meet"
  >
    <defs>
      <style>
        {
          "\n      .cart {\n        fill: #ec0200;\n      }\n\n      .plus {\n        fill: #ec0200;\n        font-family: Futura-Bold, Futura;\n        font-size: 18.33px;\n        font-weight: 700;\n        stroke: #ec0200;\n        stroke-miterlimit: 10;\n        stroke-width: 1.5px;\n      }\n\n      .number-circle {\n        fill: #046307;\n      }\n\n      .number {\n        fill: #fff;\n        font-family: Futura-Medium, Futura;\n        font-size: 16.98px;\n        font-weight: 500;\n      }\n    "
        }
      </style>
    </defs>
    <path
      className="cart"
      d="M32.82,44.34c1.19,2.63.83,4.86-1.09,6.44-1.72,1.41-4.19,1.49-6.01.17-1.99-1.44-2.5-3.68-1.44-6.46h-8.64c.73,1.4.87,2.84.27,4.31-.43,1.06-1.16,1.87-2.16,2.44-1.98,1.11-4.48.71-6.02-.95-1.4-1.52-1.98-4.17-.15-6.56-.24-.27-.49-.52-.72-.81-.52-.67-.64-1.47-.72-2.28-.64-6.47-1.28-12.94-1.92-19.42-.01-.13-.06-.26-.05-.38.11-1.31-.52-2.26-1.5-3-.3-.23-.7-.38-1.07-.45-.45-.08-.92,0-1.38-.03-.66-.04-1.14-.54-1.16-1.18-.02-.63.45-1.21,1.09-1.25.72-.04,1.44,0,2.15.01.16,0,.33.06.49.1,1.69.48,3.63,2.83,3.78,4.59.07.86.17,1.72.25,2.63h11.93c0-.73,0-1.44,0-2.15,0-.48-.01-.96,0-1.44.02-.76.54-1.3,1.23-1.3.68,0,1.21.53,1.22,1.3.02,1,0,1.99,0,2.99,0,.2,0,.4,0,.66.28,0,.49,0,.7,0,3.21,0,6.42.06,9.62-.02,2.77-.07,4.36,2.33,3.93,4.56-.61,3.16-1.06,6.36-1.6,9.53-.32,1.86-1.65,3.02-3.53,3.15-.24.02-.48.01-.72.01-6.76,0-13.53,0-20.29,0-.23,0-.47,0-.77,0,.06.59.09,1.12.19,1.64.1.48.46.74.94.81.16.02.33.01.5.01,6.91,0,13.82,0,20.73,0,.62,0,1.1-.17,1.51-.63.44-.49.92-.95,1.4-1.41.55-.53,1.29-.54,1.78-.06.5.5.51,1.23-.03,1.78-.88.88-1.79,1.73-2.75,2.65ZM7.07,24.8c.41,4.1.82,8.16,1.22,12.22.16.02.27.04.38.04,7.15,0,14.3,0,21.45,0,.76,0,1.19-.38,1.32-1.15.52-3.12,1.05-6.24,1.56-9.37.2-1.23-.28-1.78-1.51-1.78-3.22,0-6.45,0-9.67,0-.18,0-.36,0-.56,0v5.63c.94-.95,1.79-1.82,2.65-2.67.6-.6,1.34-.63,1.86-.11.53.53.5,1.23-.12,1.86-1.56,1.57-3.12,3.13-4.69,4.69-.64.64-1.3.63-1.94,0-1.57-1.56-3.13-3.12-4.69-4.69-.59-.59-.63-1.34-.11-1.86.51-.51,1.25-.46,1.86.13.77.76,1.54,1.54,2.31,2.3.09.09.21.17.33.26v-5.51H7.07ZM11.29,49.38c1.34.05,2.48-1.02,2.53-2.38.05-1.33-1.03-2.49-2.38-2.53-1.34-.04-2.49,1.04-2.53,2.39-.05,1.34,1.02,2.48,2.39,2.53ZM31.04,46.93c0-1.37-1.08-2.46-2.44-2.47-1.35,0-2.46,1.1-2.46,2.45,0,1.36,1.08,2.46,2.44,2.47,1.35,0,2.45-1.09,2.46-2.45Z"
    />
    <ellipse
      className="number-circle"
      cx={34.45}
      cy={14.91}
      rx={12.63}
      ry={13.14}
    />
    <text className="number" transform="translate(24.51 21.56) scale(1.02 1)">
      <tspan x={0} y={0}>
        {"99"}
      </tspan>
    </text>
    <text className="plus" transform="translate(40.55 12.74) scale(1.05 1)">
      <tspan x={0} y={0}>
        {"+"}
      </tspan>
    </text>
  </svg>
);
export default CartIcon;

