// src/App.js
import React, { useState, useEffect } from 'react';
import Header from './components/Header';
import MenuBar from './components/MenuBar';
import TopBar from './components/TopBar';
import SlideshowContainer from './components/SlideshowContainer';
import ActionBlock from './components/ActionBlock';
import CategoryCollection from './components/CategoryCollection';
import SmallHorizontalAd from './components/SmallHorizontalAd';
import DogProductCollection from './components/DogProductCollection';
import DogCollections from './components/DogCollections';
import FullHorizontalAd from './components/FullHorizontalAd';
import WhyShopWithUs from './components/WhyShopWithUs';
import StaffsPick from './components/StaffsPick';
import FullTwoImagesHorizontalAd from './components/FullTwoImagesHorizontalAd';
import BrandCollection from './components/BrandCollection';
import CatProductCollection from './components/CatProductCollection';
import CatCollections from './components/CatCollections';
import FeaturedBlogs from './components/FeaturedBlogs';
import BottomBar from './components/BottomBar';
import Footer from './components/Footer';
import CookieBar from './components/CookieBar';
import './App.css';
import Carna4FeatureSection from "./components/Carna4FeatureSection"
import BoxieCatFeatureSection from "./components/BoxieCatFeatureSection";
import PetServicesSection from "./components/PetServicesSection";
import HomePageAboutUsSection from "./components/HomePageAboutUsSection";
import NZNaturalDoubleFeatureAd from "./components/NZNaturalDoubleFeatureAd";

const App = () => {
  const [isMenuFullyVisible, setIsMenuFullyVisible] = useState(true);
  const [contentHeight, setContentHeight] = useState('auto');

  // Function to update the content container height
  useEffect(() => {
    const updateContentHeight = () => {
      const topBarHeight = document.querySelector('.top-bar')?.offsetHeight || 0;
      const headerHeight = document.querySelector('.header')?.offsetHeight || 0;
      const menuBarHeight = document.querySelector('.menu-bar')?.offsetHeight || 0;

      const totalHeight = topBarHeight + headerHeight + menuBarHeight;
      const viewportHeight = window.innerHeight;
      const calculatedHeight = viewportHeight - totalHeight;

      setContentHeight(`${calculatedHeight}px`);
    };

    updateContentHeight();
    window.addEventListener('resize', updateContentHeight);
    return () => window.removeEventListener('resize', updateContentHeight);
  }, []);

  // Function to check if the MenuBar is fully visible using IntersectionObserver
  useEffect(() => {
    const menuBar = document.querySelector('.menu-bar');

    if (menuBar && 'IntersectionObserver' in window) {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            setIsMenuFullyVisible(entry.isIntersecting);
          });
        },
        { threshold: 1.0 }
      );

      observer.observe(menuBar);

      // Cleanup the observer on component unmount
      return () => observer.disconnect();
    } else {
      // Fallback method using scroll event
      const checkMenuVisibilityOnScroll = () => {
        if (menuBar) {
          const rect = menuBar.getBoundingClientRect();
          const isFullyVisible = rect.top >= 0 && rect.bottom <= window.innerHeight;
          setIsMenuFullyVisible(isFullyVisible);
        }
      };

      window.addEventListener('scroll', checkMenuVisibilityOnScroll);
      checkMenuVisibilityOnScroll();
      return () => window.removeEventListener('scroll', checkMenuVisibilityOnScroll);
    }
  }, []);

  return (
    <div className="app-container">
      <TopBar message="Free shipping on orders over $75!" speed={20} className="top-bar" />
      <Header isMenuVisible={isMenuFullyVisible} className="header" />

      <MenuBar className="menu-bar" />

      <div className="content-container" style={{ height: contentHeight }}>
        <SlideshowContainer />
      </div>

      <div className="scrollable-content">
        <ActionBlock />
        <CategoryCollection />
        <NZNaturalDoubleFeatureAd />
        <DogProductCollection />
        <Carna4FeatureSection />
        <DogCollections />
        <HomePageAboutUsSection />
        <FullHorizontalAd />
        <StaffsPick />
        <FullTwoImagesHorizontalAd />
        <CatProductCollection />
        <BoxieCatFeatureSection />
        <CatCollections />
        <SmallHorizontalAd />
        <BrandCollection />
        <PetServicesSection />
        <FeaturedBlogs />
        <BottomBar />
        <Footer />
        <CookieBar />
      </div>
    </div>
  );
};

export default App;
