import React, { useState } from 'react';
import './Footer.css';
import FacebookLogo from '../assets/facebook-icon.svg';
import YoutubeLogo from '../assets/youtube-icon.svg';
import InstagramLogo from '../assets/instagram-icon.svg';
import TiktokLogo from '../assets/tiktok-icon.svg';
import AppleStore from '../assets/apple-store-download.svg';
import GooglePlay from '../assets/google-play-download.svg';
import { MdPrivacyTip } from 'react-icons/md';
import HumaneSociety from './HumaneSociety.js';
import RPAnimalServices from './RPAnimalServices.js';
import ShoppeLongLogo from './ShoppeLongLogo';
import RPCityLogo from './RPCityLogo';

const Footer = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isLocalShopping, setIsLocalShopping] = useState(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const toggleStore = () => {
    setIsLocalShopping(!isLocalShopping);
    setIsPopupOpen(false); // Close the popup after toggling
  };
  return (
    <footer className="footer">
      <div className="footer-top">
        <div className="footer-links">
          <div className="footer-top-left">
              <a className="store-selector" onClick={togglePopup}>
                {isLocalShopping ? (
                  <>
                    <RPCityLogo className="rp-city-logo" alt="Local Icon" />
                    <span className="local-shopping-text">Local Shopping</span>
                  </>
                ) : (
                  <>
                    <span className="fi fi-us" style={{ fontSize: '20px', marginRight: '5px' }}></span>
                    <span>Nationwide Shipping</span>
                  </>
                )}
              </a>
              <a href="/about">About</a>
              <a href="/careers">Careers</a>
              <a href="/affiliates">Affiliates</a>
              <a href="/jobs">Jobs</a>
              <a href="/jobs">FAQs</a>
              <a href="/jobs">Contact</a>
          </div>
          <div className="footer-top-right">
              <a href="/give-back">Give Back</a>
              <a href="/give-back">Gift Guides</a>
              <a href="/give-card">Gift Cards</a>
          </div>
        </div>
      </div>
      <div className="footer-middle">
        <div className="footer-middle-left">
            <div className="app-downloads">
              <img src={AppleStore} className="download-icon apple-store" />
              <img src={GooglePlay} className="download-icon google-play" />
            </div>
            <div className="footer-socials">
              <img src={FacebookLogo} className="social-icon facebook" />
              <img src={YoutubeLogo} className="social-icon youtube" />
              <img src={InstagramLogo} className="social-icon instagram" />
              <img src={TiktokLogo} className="social-icon tiktok" />
            </div>
        </div>
        <div className="footer-middle-right">
            <a href="https://thelittlepetshoppe.com/shop-local/" target="_blank" rel="noopener noreferrer"> <ShoppeLongLogo className="shoppe-logo" /> </a>
            <a href="https://humanesocietysoco.org/" target="_blank" rel="noopener noreferrer"> <HumaneSociety height="36px" className="humane-society-logo" /> </a>
            <a href="https://rpanimalshelter.org/" target="_blank" rel="noopener noreferrer"> <RPAnimalServices height="36px" className="rp-animal-services-logo" /> </a>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="footer-bottom-first-line">
          <a href="/terms-of-use">Terms of Use</a>
          <a href="/privacy-policy">Privacy Policy (Updated Nov, 2024)</a>
          <a href="/interest-based-ads">Interest-Based Ads</a>
          <a href="/accessibility">Accessibility</a>
          <a href="/return-policy">Return & Refund Policy</a>
          <a href="/shipping-policy">Shipping Policy</a>
          <a href="/terms-of-sale">Terms of Sale</a>
          <a href="/sustainability">Sustainability</a>
          <span className="privacy-choices-wrapper">
            <MdPrivacyTip className="privacy-icon" size={18} />
            <a href="/privacy-choices">Your Privacy Choices</a>
          </span>
        </div>
        <p className="copyright">Copyright © 2024, Better Brands IT Solutions, LLC</p>
      </div>
      {/* Popup Modal */}
      {isPopupOpen && (
        <div className="popup-overlay">
          <div className="popup-content">
            <h2>Change Store</h2>
            <button className="close-button" onClick={togglePopup}>×</button>
            {isLocalShopping ? (
              <>
                <p>Not in Rohnert Park, CA? Shop our online store for nationwide delivery.</p>
                <button className="store-toggle-button" onClick={toggleStore}>
                  <span className="fi fi-us" style={{ fontSize: '20px', marginRight: '5px' }}></span>
                  Nationwide Delivery
                </button>
              </>
            ) : (
              <>
                <p>Want to shop local? Shop in-store items at our Rohnert Park, CA location for local delivery or pickup.</p>
                <button className="store-toggle-button" onClick={toggleStore}>
                  <RPCityLogo className="rp-city-logo" alt="Local Icon" />
                  <span className="local-shopping-text">Shop In-Store</span>
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </footer>
  );
};

export default Footer;
