import * as React from "react"
const BoxieCatOdorIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    viewBox="0 0 350 105"
    {...props}
  >
    <defs>
      <style>
        {
          ".boxiecat-odor1{stroke:#d80505}.boxiecat-odor1,.boxiecat-odor4,.boxiecat-odor6,.boxiecat-odor8{fill-rule:evenodd}.boxiecat-odor1,.boxiecat-odor4{fill:none;stroke-miterlimit:10}.boxiecat-odor4{stroke-width:5px}.boxiecat-odor1{stroke-width:4px}.boxiecat-odor4{stroke:#868d93}.boxiecat-odor6{fill:#d80505}.boxiecat-odor8{fill:#868d93}"
        }
      </style>
    </defs>
    <path
      d="M58.5 8.5c4.4.7 6.7 3.4 7 8-.2 3.9-1.7 7.3-4.5 10-1.1 4.4-.3 8.4 2.5 12-.3 1-1 1.7-2 2-3.8-2.2-5.5-5.6-5-10 1-4.4 2.4-8.7 4-13-.4-3.1-1-6.1-2-9Z"
      style={{
        fill: "#8a9096",
        fillRule: "evenodd",
      }}
    />
    <path
      d="M44.5 12.5c3.9 1.8 5.9 5 6 9.5-.5 3.9-2 7.4-4.5 10.5-.9 3.9 0 7.4 2.5 10.5-.1 1.3-.8 2.1-2 2.5-3.3-2.7-4.9-6.2-5-10.5 1.6-4.3 3.3-8.6 5-13-.5-2.6-1.5-4.9-3-7 .2-.9.6-1.7 1-2.5Z"
      className="boxiecat-odor8"
    />
    <path
      d="M78.5 42.5c-1.3 1-2.6 2-4 3-2.4-3-3.7-6.5-4-10.5 1.7-4.3 3.3-8.7 5-13 0-2.7-1.1-5.1-3-7 .3-1.9 1.3-2.6 3-2 3.8 3.7 4.9 8.2 3.5 13.5-2.2 2.8-3.7 6-4.5 9.5.2 2.9 1.5 5.1 4 6.5Z"
      style={{
        fill: "#888f95",
        fillRule: "evenodd",
      }}
    />
    <path
      d="M252.5 20.5c1.3-.2 2.5.2 3.5 1 .7 2 .7 4 0 6-1.3 1.3-2.7 1.3-4 0-.7-2.4-.5-4.8.5-7ZM243.5 29.5c2-.2 4 0 6 .5 1 1 1.2 2.2.5 3.5-2.1 1.1-4.2 1.3-6.5.5-1.2-1.5-1.2-3 0-4.5ZM258.5 29.5c2-.2 4 0 6 .5 1 1 1.2 2.2.5 3.5-2.1 1.1-4.2 1.3-6.5.5-1.2-1.5-1.2-3 0-4.5ZM252.5 35.5c1.3-.2 2.5.2 3.5 1 .7 2 .7 4 0 6-1.3 1.3-2.7 1.3-4 0-.7-2.4-.5-4.8.5-7Z"
      className="boxiecat-odor6"
    />
    <path
      d="M84.6 56.7c-1.3 1-2.3 2.2-3 3.8-2.1 2.2-4.5 3.8-7.1 4.8-3.2-.7-5.3-3-6.4-6.7v-4.8c2.1-3.1 4.3-6 6.8-8.6 1-1 2-1.9 3-2.9 4.2-5.9 8.6-6.2 13.2-1 .6 3 .3 5.9-.8 8.6-1.8 2.5-3.7 4.7-5.6 6.7Z"
      style={{
        strokeWidth: 5,
        fill: "none",
        strokeMiterlimit: 10,
        fillRule: "evenodd",
        stroke: "#d80505",
      }}
    />
    <path
      d="M221.5 37.5c1.8-.3 3.5 0 5 1 1.1 3.5-.3 5-4 4.5-.9-1.7-1.2-3.5-1-5.5ZM281.5 37.5h5c.2 1.7 0 3.4-.5 5-1.3 1.3-2.7 1.3-4 0-.5-1.6-.7-3.3-.5-5ZM192.5 40.5c1.3-.2 2.5.2 3.5 1 .7 2 .7 4 0 6-1.3 1.3-2.7 1.3-4 0-.7-2.4-.5-4.8.5-7Z"
      className="boxiecat-odor6"
    />
    <path
      d="M138.5 40.5c5.8 4.3 11.1 9.1 16 14.5-4.2 5.5-9 10.3-14.5 14.5-1.9-.3-2.6-1.3-2-3L149.5 55 138 43.5c-.5-1.1-.3-2.1.5-3Z"
      style={{
        fill: "#878e94",
        fillRule: "evenodd",
      }}
    />
    <path
      d="M312.5 43.5c1.3-.2 2.5.2 3.5 1 .7 2 .7 4 0 6-1.3 1.3-2.7 1.3-4 0-.7-2.4-.5-4.8.5-7Z"
      className="boxiecat-odor6"
    />
    <path
      d="M27.9 60.4c.9 1.3 2 2.4 3.5 3.2 2.2 1.9 3.1 4.3 2.6 7.3-.2 1.8-1.1 3.1-2.6 4-2.5.7-5.1 1-7.8.8-1.3-1.3-2.7-2.3-4.3-3.2-3.4-2.7-6.9-5.4-10.4-8.1-3.5-7.6-.9-11.2 7.8-10.9 3.8 2.3 7.6 4.6 11.3 6.9Z"
      className="boxiecat-odor1"
    />
    <path
      d="M183.5 49.5c2-.2 4 0 6 .5.8 1 1.2 2.2 1 3.5h-8c0-1.5.3-2.8 1-4ZM198.5 49.5c2-.2 4 0 6 .5.8 1 1.2 2.2 1 3.5h-8c0-1.5.3-2.8 1-4ZM303.5 52.5c2-.2 4 0 6 .5.8 1 1.2 2.2 1 3.5h-8c0-1.5.3-2.8 1-4ZM318.5 52.5c2-.2 4 0 6 .5.8 1 1.2 2.2 1 3.5h-8c0-1.5.3-2.8 1-4Z"
      className="boxiecat-odor6"
    />
    <path
      d="M65.5 54.5v5c-7-.2-14 0-21 .5-4.2.3-8.2 1.2-12 2.5-1.7-1-3-2.3-4-4 12-3.5 24.4-4.8 37-4Z"
      style={{
        fill: "#848b91",
        fillRule: "evenodd",
      }}
    />
    <path
      d="M238.5 54.5c12.7-.3 25.4.2 38 1.5 7.4.9 13.9 3.7 19.5 8.5 5 14.4.1 22.9-14.5 25.5-20.9 4-41.5 3.3-62-2-3.1-1.1-5.6-2.9-7.5-5.5-.7-6-.7-12 0-18l4.5-4.5c7.3-2.7 14.6-4.5 22-5.5Z"
      className="boxiecat-odor4"
    />
    <path
      d="M192.5 55.5c1.3-.2 2.5.2 3.5 1 .7 2 .7 4 0 6-1.3 1.3-2.7 1.3-4 0-.7-2.4-.5-4.8.5-7Z"
      className="boxiecat-odor6"
    />
    <path
      d="M87.5 57.5c13.3.3 18.3 7 15 20-1.4 1-3.1 1.7-5 2v-6c-21.4 5.5-43.1 6.5-65 3 1.8-1.2 2.8-2.8 3-5 17.3 2.8 34.7 2.6 52-.5 3.7-.8 7-2.3 10-4.5-4-3.2-8.7-4.8-14-5 1-1.7 2.3-3 4-4Z"
      className="boxiecat-odor8"
    />
    <path
      d="M312.5 58.5c1.3-.2 2.5.2 3.5 1 .7 2 .7 4 0 6-1.3 1.3-2.7 1.3-4 0-.7-2.4-.5-4.8.5-7Z"
      className="boxiecat-odor6"
    />
    <path
      d="M18.5 73.5c1.9 1.1 3.6 2.4 5 4-.2 1.6.1 3.1 1 4.5 4.2 1.7 8.5 3 13 4 14.3 1.8 28.6 2 43 .5-.8 1.5-1.1 3.2-1 5-17.1 1.8-34.1 1-51-2.5-3.7-1-6.8-2.8-9.5-5.5-.5-3.3-.7-6.7-.5-10Z"
      className="boxiecat-odor8"
    />
    <path
      d="M102.5 77.5c8.4-.8 12.2 2.9 11.5 11-.6 1.5-1.4 2.8-2.5 4-7.8 3-15.8 5.3-24 7-4.6-.6-7.3-3.3-8-8-.1-1.8.2-3.5 1-5 1.1-1.3 2.5-2.5 4-3.5 4.4-1.2 8.7-2.4 13-3.5 1.9-.3 3.6-1 5-2Z"
      className="boxiecat-odor1"
    />
    <path d="M213 64.3s40.5 23.3 83.3 0" className="boxiecat-odor4" />
  </svg>
)
export default BoxieCatOdorIcon
