import React from "react";
import "./Carna4ProductDisplay.css";
import spiralNotebookEffect from "../assets/spiral-notebook-effect.svg";

const Carna4ProductDisplay = () => {
  return (
    <div className="carna4-product">
      {/* Product Box */}
      <div className="carna4-product-box">
        <img
          src="https://cdn.thelittlepetshoppe.com/spiral-effect.svg"
          alt="Placeholder Image"
          className="carna4-product-box-spiral"
        />
        <h3 className="carna4-product-title">
          Easy-chew <br /> Lamb <br /> Formula <br /> Dog Food
        </h3>
        <p className="carna4-product-description">
          Carna4 Easy-Chew Lamb <br />
          variety is whole food nutrition <br />
          for all life stages of your dog <br />
          customized with a slightly <br />
          softer, smaller nugget.
        </p>
        <h2 className="carna4-product-price">$26.89</h2>
      </div>

      {/* Images */}
      <img
        src="https://cdn.thelittlepetshoppe.com/Carna4-tag.png"
        alt="Placeholder Image"
        className="carna4-product-tag"
      />
      <img
        src="https://cdn.thelittlepetshoppe.com/Carna4-627843113447-bag.png"
        alt="Placeholder Image"
        className="carna4-product-bag"
      />
      <img
        src="https://cdn.thelittlepetshoppe.com/carna4-food-nuggets.png"
        alt="Placeholder Image"
        className="carna4-product-food"
      />
    </div>
  );
};

export default Carna4ProductDisplay;
