import * as React from "react";
const ChatIcon = ({ fill = "#828282", className = "", width = "42px", height = "32px" }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width={width} // Dynamic width
    height={height} // Dynamic height
    viewBox="0 0 42 32"
    xmlSpace="preserve"
  >
    <g>
      <path
        fill={fill}
        d="M35.652,14.023c-0.264-0.082-0.544,0.062-0.628,0.325c-0.083,0.263,0.062,0.544,0.325,0.628 C39.257,16.221,41,18.078,41,21c0,2.599-2.371,4.616-3.783,5.588C37.081,26.682,37,26.835,37,27v3.823 c-0.73-0.272-2.003-0.89-3.126-2.155c-0.117-0.131-0.295-0.191-0.468-0.159c-0.285,0.055-0.576,0.133-0.871,0.212 C32.025,28.858,31.499,29,31,29c-2.568,0-4.366-0.552-6.204-1.903c-0.224-0.164-0.535-0.115-0.699,0.107 c-0.164,0.223-0.116,0.535,0.106,0.699C26.23,29.393,28.199,30,31,30c0.631,0,1.223-0.159,1.795-0.313 c0.178-0.049,0.355-0.097,0.53-0.138c1.869,1.974,3.983,2.423,4.075,2.441c0.033,0.007,0.066,0.01,0.1,0.01 c0.114,0,0.227-0.039,0.316-0.113C37.933,31.792,38,31.65,38,31.5v-4.239c2.582-1.841,4-4.057,4-6.261 C42,17.619,39.983,15.402,35.652,14.023z"
      />
      <path
        fill={fill}
        d="M33,13.5C33,5.804,25.982,0,16.677,0C7.325,0,0,5.931,0,13.502c0,4.539,3.211,7.791,6,9.759v6.636 c0,0.17,0.086,0.327,0.228,0.42c0.083,0.053,0.177,0.08,0.272,0.08c0.069,0,0.139-0.015,0.205-0.044 c0.146-0.065,3.559-1.616,6.479-4.809c1.265,0.235,2.696,0.461,3.994,0.461C26.641,26.005,33,20.979,33,13.5z M17.177,25.005 c-1.31,0-2.799-0.251-4.083-0.496c-0.173-0.031-0.351,0.028-0.468,0.159c-2.05,2.312-4.459,3.781-5.626,4.414V23 c0-0.165-0.081-0.318-0.217-0.412C4.145,20.773,1,17.725,1,13.502C1,6.491,7.886,1,16.677,1C25.413,1,32,6.374,32,13.5 C32,20.382,26.043,25.005,17.177,25.005z"
      />
      <path
        fill={fill}
        d="M16.5,11.5c-1.103,0-2,0.897-2,2s0.897,2,2,2s2-0.897,2-2S17.603,11.5,16.5,11.5z M16.5,14.5 c-0.551,0-1-0.448-1-1s0.449-1,1-1s1,0.448,1,1S17.051,14.5,16.5,14.5z"
      />
      <path
        fill={fill}
        d="M23.5,11.5c-1.103,0-2,0.897-2,2s0.897,2,2,2s2-0.897,2-2S24.603,11.5,23.5,11.5z M23.5,14.5 c-0.551,0-1-0.448-1-1s0.449-1,1-1s1,0.448,1,1S24.051,14.5,23.5,14.5z"
      />
      <path
        fill={fill}
        d="M9.5,11.595c-1.103,0-2,0.897-2,2s0.897,2,2,2s2-0.897,2-2S10.603,11.595,9.5,11.595z M9.5,14.595 c-0.551,0-1-0.448-1-1s0.449-1,1-1s1,0.448,1,1S10.051,14.595,9.5,14.595z"
      />
    </g>
  </svg>
);
export default ChatIcon;
