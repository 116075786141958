import * as React from "react"
const BoxieCatCleanIcon = ({className}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    viewBox="0 0 195 139"
    className={className}
  >
    <defs>
      <style>{".boxiecat-clean1{fill-rule:evenodd;fill:#d80505}"}</style>
    </defs>
    <path
      d="M93.5 4.5c1.6-.2 3.1.1 4.5 1 .8 2.9.6 5.8-.5 8.5-1.6.7-3.1.6-4.5-.5-.7-3.1-.6-6.1.5-9ZM17.5 15.5h5v9h-5v-9ZM168.5 15.5h5v9c-1.6.2-3.1-.1-4.5-1-.5-2.6-.7-5.3-.5-8ZM83.5 15.5c2-.2 4 0 6 .5 1.7 1.1 2.2 2.6 1.5 4.5-2.7 1.1-5.6 1.3-8.5.5-1.3-2.1-1-4 1-5.5ZM100.5 16.5h9c.7 4-1 5.7-5 5-4.4.6-5.8-1.1-4-5ZM93.5 23.5c1.6-.2 3.1.1 4.5 1 .7 2.3.7 4.7 0 7-1.7 2.7-3.3 2.7-5 0-.7-2.8-.6-5.4.5-8ZM6.5 26.5c2.7-.2 5.4 0 8 .5 1.5 2 1.2 3.7-1 5-5.7 1.8-8 0-7-5.5ZM25.5 26.5c2.7-.2 5.4 0 8 .5 1.4 1.9 1 3.6-1 5-2.3.7-4.7.7-7 0-1.3-1.8-1.3-3.7 0-5.5ZM158.5 26.5c2.4-.2 4.7 0 7 .5 1.1 1.4 1.2 2.9.5 4.5-2.4 1.1-4.9 1.3-7.5.5-2.6-1.9-2.6-3.8 0-5.5ZM176.5 26.5c2.7-.2 5.4 0 8 .5 1.4 1.9 1 3.6-1 5-5.7 1.8-8.1 0-7-5.5ZM54.5 28.5c6.7-1.2 8.4 1 5 6.5-5.2.6-6.9-1.6-5-6.5ZM132.5 27.5c4.8 1.1 5.8 3.6 3 7.5-4.5.8-6.3-1-5.5-5.5 1-.6 1.8-1.2 2.5-2ZM18.5 33.5c3.4-.1 4.7 1.5 4 5 .7 4-1 5.7-5 5-.2-3.4 0-6.8 1-10ZM169.5 33.5c1.3-.2 2.5.2 3.5 1 .7 2.7.7 5.3 0 8-1.3 1.3-2.7 1.3-4 0-.7-3.1-.6-6.1.5-9Z"
      className="boxiecat-clean1"
    />
    <path
      d="M9.5 57.5c57.3-.2 114.7 0 172 .5l2.5 2.5c.7 5 .7 10 0 15-1.5 2.4-3.7 3.4-6.5 3-1.2 15.7-3 31.4-5.5 47l-2.5 2.5c-49.3.7-98.7.7-148 0l-2.5-2.5c-2.1-15.7-3.9-31.3-5.5-47-3.3.5-5.5-.8-6.5-4-.7-4.7-.7-9.3 0-14 1-.9 1.9-1.9 2.5-3Z"
      style={{
        fill: "none",
        stroke: "#82898f",
        strokeMiterlimit: 10,
        strokeWidth: 5,
        fillRule: "evenodd",
      }}
    />
    <path
      d="M15.6 78.2h159.9"
      style={{
        fill: "none",
        stroke: "#82898f",
        strokeMiterlimit: 10,
        strokeWidth: 5,
      }}
    />
  </svg>
)
export default BoxieCatCleanIcon
