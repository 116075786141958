import * as React from "react"
const BoxieCatCatIcon = ({className}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    viewBox="0 0 235 209"
    className={className}
  >
    <defs>
      <style>{".boxiecat-caticon3{fill-rule:evenodd;fill:#858c92}"}</style>
    </defs>
    <path
      d="M71.5 63.5h3c.3-2.9 0-5.6-1-8-3.1-.3-6.1-1-9-2-1.7-2.7-1-4.3 2-5 2.9.8 5.9 1.1 9 1 .2-3.1 1.1-6.1 2.5-9 .3-7.3.7-14.7 1-22 1-1.4 2.3-2.1 4-2 6 1.8 12 3.5 18 5 8.3-1.3 16.7-1.3 25 0 5.3-1.1 10.4-2.6 15.5-4.5 3.5-1.1 6.3-.3 8.5 2.5 0 10.1.9 20.1 2.5 30 3.9-.5 7.8-.5 11.5 0 .7 1.6.6 3.1-.5 4.5-3 .7-6 1.2-9 1.5v8c3.1-.1 6.1.4 9 1.5.9 1.4 1.2 2.9 1 4.5-3.7.2-7.4-.2-11-1-3.4 3.8-7.1 7.5-11 11 7.8 10.3 11.2 21.9 10 35 16-.2 32 0 48 .5 1.4.5 2.6 1.3 3.5 2.5.7 5.3.7 10.7 0 16-1.5 2.4-3.7 3.4-6.5 3-1.1 15.7-3 31.4-5.5 47l-2.5 2.5c-50 .7-100 .7-150 0-1.8-1-3-2.5-3.5-4.5-1.9-15-3.7-30-5.5-45-3.3.5-5.5-.8-6.5-4-.7-4.7-.7-9.3 0-14l3.5-3.5c16.3-.5 32.7-.7 49-.5-1.3-12.8 1.7-24.5 9-35-3.2-3.4-6.2-6.9-9-10.5-4.2.2-8.4 0-12.5-.5-.7-1.3-.5-2.5.5-3.5 2.2-1.2 4.5-1.7 7-1.5Z"
      style={{
        fillRule: "evenodd",
        fill: "none",
        stroke: "#858c92",
        strokeMiterlimit: 10,
        strokeWidth: 4,
      }}
    />
    <path
      d="M93 42.9c4.6-.5 9 .3 13 2.5 1.6 2.5 1.1 4.3-1.5 5.5-2.1-1.5-4.4-2.5-7-3-2.1 1.1-4.2 1.6-6.5 1.5-1.6-2.9-.9-5.1 2-6.5Z"
      className="boxiecat-caticon3"
    />
    <path
      d="M125 42.9c4.1-.3 8.1.2 12 1.5 1.7 2.8 1 4.6-2 5.5-1.7-.7-3.3-1.3-5-2-2.9 1.5-5.9 2.3-9 2.5-1.2-1.5-1.2-3 0-4.5 1.4-1 2.7-2 4-3Z"
      style={{
        fill: "#888f94",
        fillRule: "evenodd",
      }}
    />
    <path
      d="M108 55.9c3.7-.2 7.3 0 11 .5.5.4.8.9 1 1.5-1.5 1.7-2.5 3.7-3 6 2.3 2 4.6 3.8 7 5.5-.5 1.9-1.9 2.8-4 2.5-2.2 0-4.2-.8-6-2-3.2 1.5-6.5 2.2-10 2v-5c7.6-.9 8.6-3.9 3-9 .4-.7.7-1.4 1-2Z"
      className="boxiecat-caticon3"
    />
    <path
      d="M172.5 73.5c4.7-.5 9 .5 13 3 9.8-5.6 16.4-3.1 20 7.5-.4 2.6-1.3 5.1-2.5 7.5-4.6 4.1-9.1 8.2-13.5 12.5-2.2 1.4-4.5 1.7-7 1-6.2-4.9-11.7-10.4-16.5-16.5-1.6-6.7.5-11.7 6.5-15Z"
      style={{
        strokeWidth: 5,
        fillRule: "evenodd",
        fill: "none",
        stroke: "#858c92",
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="M32 134.2h164.1v5H32z"
      style={{
        fill: "#858c92",
      }}
    />
  </svg>
)
export default BoxieCatCatIcon
