// src/components/HeaderOptions.js
import React from 'react';
import './ActionBlock.css'; // Import the CSS file


const ActionBlock = () => {
  return (
    <div className="header-options">
      {/* First Block: Sign In */}
      <div className="option-block">
        <div className="text-container">
            Hi, <br /> Sign in
        </div>
      </div>



      {/* Second Block: Autoship */}
      <div className="option-block">
        <div className="text-container">
            Save 15% Today <br /> Set up Autoship
        </div>
      </div>

      {/* Third Block: Track Package */}
      <div className="option-block">
        <div className="text-container">
            Recent Order <br /> Track Package
        </div>
      </div>

      {/* Fourth Block: Shop Now */}
      <div className="option-block">
        <div className="text-container">
            Astro Offers <br /> Shop Now
        </div>
      </div>
    </div>
  );
};

export default ActionBlock;
