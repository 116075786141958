// src/components/FullTwoImagesHorizontalAd.js
import React from 'react';
import './FullTwoImagesHorizontalAd.css';
import sampleImage1 from '../assets/ad-image1.png';
import sampleImage2 from '../assets/ad-image2.png';

const FullTwoImagesHorizontalAd = () => {
  return (
    <div className="two-images-ad-wrapper">
        <div className="two-images-full-horizontal-ad">
          <div className="ad-image-container-overflow">
            <img src={sampleImage1} alt="Ad for products" className="ad-image" />
          </div>
          <div className="ad-content">
            <h1 className="ad-title">Ad for some products</h1>
            <p className="ad-description">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eleifend, purus quis iaculis rutrum, Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eleifend, purus quis iaculis rutrum, Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </p>
            <button className="shop-now-button">Shop Now</button>
          </div>
          <div className="ad-image-container-no-overflow">
            <img src={sampleImage2} alt="Ad for products" className="ad-image" />
          </div>
        </div>
        <div class="striped-line"></div>
    </div>
  );
};

export default FullTwoImagesHorizontalAd;