import React from "react";
import PropTypes from "prop-types";
import "./FloatingContainer.css";

const FloatingContainer = ({
  children,
  width = "300px",
  height = "auto",
  maxWidth = "none",
  maxHeight = "none",
  trianglePosition = "center", // Options: 'left', 'center', 'right', 'default', or custom
  customTriangleOffset, // Pass percentage or pixel value for custom positioning
  leftPosition = "auto", // Custom left offset
  rightPosition = "auto", // Custom right offset
}) => {
  const containerStyles = {
    width,
    height,
    maxWidth,
    maxHeight,
    left: leftPosition,
    right: rightPosition,
    marginLeft: trianglePosition === "center" ? "auto" : "unset",
    marginRight: trianglePosition === "center" ? "auto" : "unset",
  };

  const triangleStyles = {
    left:
      trianglePosition === "left"
        ? "10px"
        : trianglePosition === "default"
        ? "unset"
        : customTriangleOffset
        ? customTriangleOffset
        : "auto",
    right:
      trianglePosition === "right"
        ? "10px"
        : trianglePosition === "default"
        ? "20%"
        : customTriangleOffset
        ? "unset"
        : "auto",
    margin:
      trianglePosition === "center"
        ? "0 auto"
        : customTriangleOffset
        ? "unset"
        : "unset",
    transform:
      trianglePosition === "center" ? "translateX(-50%)" : "none",
  };

  return (
    <div className="floating-container" style={containerStyles}>
      <div className="triangle" style={triangleStyles}></div>
      <div className="floating-scrollable-wrapper">
        <div className="floating-scrollable-content">
          <div className="container-content">{children}</div>
        </div>
      </div>
    </div>
  );
};

// Prop validation
FloatingContainer.propTypes = {
  children: PropTypes.node.isRequired,
  width: PropTypes.string, // Container width
  height: PropTypes.string, // Container height
  maxWidth: PropTypes.string, // Max width of the container
  maxHeight: PropTypes.string, // Max height of the container
  trianglePosition: PropTypes.oneOf([
    "left",
    "center",
    "right",
    "default",
    "custom",
  ]), // Allowed values
  customTriangleOffset: PropTypes.string, // e.g., '50px', '80%'
  leftPosition: PropTypes.string, // Custom left position (in pixels or percentage)
  rightPosition: PropTypes.string, // Custom right position (in pixels or percentage)
};

// Default props
FloatingContainer.defaultProps = {
  width: "auto",
  height: "auto",
  maxWidth: "none",
  maxHeight: "none",
  trianglePosition: "center",
  leftPosition: "auto",
  rightPosition: "auto",
};

export default FloatingContainer;
