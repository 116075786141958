import React, { useRef } from 'react';
import './FeaturedBlogs.css'; // Import CSS for styling
import { FaImage } from 'react-icons/fa'; // Import placeholder icon

const FeaturedBlogs = () => {
  const containerRef = useRef(null);

  const blogs = [
      {
        id: 1,
        title: '10 Tips for a Healthy Pet',
        description: 'Learn how to keep your pet healthy with these 10 simple tips...',
        image: '',
      },
      {
        id: 2,
        title: 'The Best Dog Breeds for Families',
        description: 'Discover which dog breeds are perfect for your family...',
        image: '',
      },
      {
        id: 3,
        title: 'Understanding Your Cat’s Behavior',
        description: 'Cats can be mysterious creatures. Here’s how to understand them...',
        image: '',
      },
      {
        id: 4,
        title: 'Understanding Your Cat’s Behavior',
        description: 'Cats can be mysterious creatures. Here’s how to understand them...',
        image: '',
      }
  ];

  // Default placeholder image (use an actual URL or icon if desired)
  const placeholderImage = (
    <FaImage className="placeholder-icon" />
  );

  return (
    <div className="featured-blogs-wrapper">
      <h2 className="collection-title">FEATURED BLOGS</h2>
      <h3 className="collection-sub-title">Check Out Our Latest Blogs!</h3>
      <div className="blog-collections" ref={containerRef}>
        {blogs.map((blog) => (
          <div className="blog-collection-item" key={blog.id}>
            <div className="blog-collection-image-container">
              {blog.image ? (
                <img
                  src={blog.image}
                  alt={blog.title}
                  className="blog-collection-image"
                />
              ) : (
                placeholderImage // Fallback to placeholder if no image
              )}
            </div>
            <div className="blog-info">
              <p className="blog-title">{blog.title}</p>
              <p className="blog-description">
                {blog.description.length > 100
                  ? `${blog.description.substring(0, 97)}...`
                  : blog.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FeaturedBlogs;
