// src/components/SmallHorizontalAd.js
import React from 'react';
import './SmallHorizontalAd.css'; // Import the CSS for styling
import sampleImage from '../assets/ad image.png'; // Replace with your image
import { FaGift } from 'react-icons/fa'; // Example icon for gift decoration

const SmallHorizontalAd = () => {
  return (
    <div className="small-horizontal-ad-wrapper">
      <div className="small-horizontal-ad">
          <div className="image-section">
            <img src={sampleImage} alt="Ad Product" className="ad-image" />
          </div>
          <div className="text-section">
            <h3 className="ad-subtitle">Elevate your dog's nutrition</h3>
            <h1 className="ad-title">WITH INCEPTION PORK RECIPE</h1>
            <button className="shop-now-button">Shop Now</button>
          </div>
      </div>
    </div>
  );
};

export default SmallHorizontalAd;
