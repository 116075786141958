import React, { useState } from "react";
import "./SignInOptionBlock.css";
import SignInIcon from "./SignInIcon"; // Replace with your SignInIcon component
import ArrowIcon from "./ArrowIcon"; // Replace with your ArrowIcon component
import FloatingContainer from "./FloatingContainer"; // Reusable FloatingContainer component
import SignInPopup from "./SignInPopup"; // The Sign-In Pop-Up component

const SignInOptionBlock = () => {
  const [isPopupVisible, setPopupVisible] = useState(false);

  const handlePopupToggle = () => {
    setPopupVisible(true); // Show the pop-up and hide the container
  };

  const handlePopupClose = () => {
    setPopupVisible(false); // Close the pop-up and show the container again
  };

  return (
    <>
      {/* Conditionally Render Sign-In Container */}
      {!isPopupVisible && (
        <div className="sign-in-container">
          {/* Triggering Div */}
          <div className="sign-in-option">
            <SignInIcon width="22px" height="22px" className="icon" />
            <span>
              Sign in <ArrowIcon />
            </span>
          </div>

          {/* Dropdown Menu */}
          <FloatingContainer leftPosition="-60px" customTriangleOffset="67%">
            <div className="floating-content">
              <button
                className="sign-in"
                onClick={handlePopupToggle} /* Show Pop-Up */
              >
                Sign in
              </button>
              <button className="create-account">Create an Account</button>
              <div className="sign-in-container-text">
                Shopped our store recently?
              </div>
              <button className="register-account">Register Account</button>
            </div>
          </FloatingContainer>
        </div>
      )}

      {/* Sign-In Pop-Up */}
      {isPopupVisible && <SignInPopup onClose={handlePopupClose} />}
    </>
  );
};

export default SignInOptionBlock;
